import { gql } from "@apollo/client"

const createMatchMutation = gql(`
  mutation CreateMatch($input: CreateMatchInput!) {
    createMatch(input: $input) {
      match {
        id
      }
    }
  }
`)

export default createMatchMutation