export default function TableContainer({children}) {
  return (
      <div className="flex flex-col">
        <div className="-m-1.5 ">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div
                className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-slate-900 dark:border-gray-700">
              {children}
            </div>
          </div>
        </div>
      </div>
  )
}