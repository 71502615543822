import Select from "react-select"
import statuses from "./STATUS_CHOICES.json"

export default function StatusesSelect ({selectedStatus, ...rest}){

  let options = []

  for (const status in statuses){
    options.push({value: status, label: statuses[status]})
  }

  return(
      <Select
          options={options}
          {...{
            ...(selectedStatus?.length > 0
                ? { value: options.filter((option) => selectedStatus.includes(option.value)) }
                : {}),
            ...rest
          }}/>
  )
}
