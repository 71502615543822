import { useSearchParams } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import ElectrocnicInvoicesQuery from "queries/transactions/ElectrocnicInvoicesQuery"
import { useCallback, useEffect, useMemo } from "react"

export default function useInvoicesQuery (){
  const[searchParams] = useSearchParams()
  const [fetchInvoices, { data, loading, error, called, refetch, fetchMore}] = useLazyQuery(ElectrocnicInvoicesQuery)

  const variables = useMemo(() => ({
    ...(searchParams.has('name') && { name: searchParams.get('name') }),
    ...(searchParams.has('after') && { after: searchParams.get('after') }),
    ...(searchParams.has('orderBy') && { orderBy: searchParams.get('orderBy') }),
    ...(searchParams.has('issuedAt_Gte') && { issuedAt_Gte: searchParams.get('issuedAt_Gte') }),
    ...(searchParams.has('issuedAt_Lte') && { issuedAt_Lte: searchParams.get('issuedAt_Lte') }),
  }), [searchParams])

  const fetch = useCallback(async () => await fetchInvoices({variables}),[fetchInvoices, variables])

  useEffect(() => {
    if(called) fetch()
  }, [called, variables, fetch])

  return {fetch, data, loading, error, variables, refetch, fetchMore}
}