import {useFragment, useMutation} from "@apollo/client"
import UpdateTransactionCategory from "mutations/categories/UpdateTransactionCategory"
import SubCategoryFragment from "queries/transactions/SubCategoryFragment"
import transactionCategoriesQuery from "queries/transactions/TransactionCategoriesQuery"
import {Formik} from "formik"
import transactionsQuery from "queries/transactions/TransactionsQuery"
import useTransactionsQuery from "hooks/transactions/useTransactionsQuery"
import TransactionStatsQuery from "queries/transactions/TransactionStatsQuery"
import { useSearchParams } from "react-router-dom"

export default function EditCategoryForm({handleSaveBtn, subCategoryData}) {
  const [searchParams] = useSearchParams()
  const {variables} = useTransactionsQuery()

  const [updateSubCategory] = useMutation(UpdateTransactionCategory)

  const {complete, data} = useFragment({
        fragment: SubCategoryFragment,
        fragmentName: "SubCategoryFields",
        from: {
          __typename: "TransactionCategoryNode",
          id: subCategoryData.id,
        },
      }
  )

  const initialValues = {
    name: data.name,
    excludeFromStats: data.excludeFromStats,
    excludeByDefault: data.excludeByDefault
  }

  const handleSubmit = (values) => {
    updateSubCategory({
      variables: {
        input: {
          id: data.id,
          name: values.name,
          excludeFromStats: values.excludeFromStats,
          excludeByDefault: values.excludeByDefault,
        }
      },
      refetchQueries: [
        {
          query: transactionsQuery,
          variables
        },
        {
          query: transactionCategoriesQuery
        },
        {
          query: TransactionStatsQuery,
          variables: {
            ...(searchParams.has('date_Gte') && { date_Gte: searchParams.get('date_Gte') }),
            ...(searchParams.has('date_Lte') && { date_Lte: searchParams.get('date_Lte') }),
          }
        }
      ]
    })
    handleSaveBtn()
  }

  const handleCancelBtn = () => {
    handleSaveBtn()
  }

  return (
      complete
          ? (
              < >
                <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                  {
                    ({values, handleChange, handleSubmit}) => (
                        <form onSubmit={handleSubmit} className="flex flex-col gap-y-1 ">
                          <input
                              className="py-1.5 px-1 w-full border-gray-200 rounded-md text-xs focus:border-blue-500 focus:ring-blue-500 text-center text-gray-500"
                              name="name"
                              onChange={handleChange}
                              value={values.name}
                              type="text"
                          />
                          <div className="my-0.5 flex flex-col gap-y-1">
                            <div className="flex my-auto">
                              <input
                                  type="checkbox"
                                  className=" border-gray-200 rounded text-blue-600 focus:ring-blue-500 "
                                  id="excludeByDefault"
                                  name="excludeByDefault"
                                  onChange={handleChange}
                                  value={values.excludeByDefault}
                                  checked={values.excludeByDefault}/>
                              <label
                                  htmlFor="excludeByDefault"
                                  className="text-xs ml-2 text-gray-500 m-auto">
                                Excluir por defecto
                              </label>
                            </div>
                            <div className="flex my-auto">
                              <input
                                  type="checkbox"
                                  className=" border-gray-200 rounded text-blue-600 focus:ring-blue-500 "
                                  id="excludeFromStats"
                                  onChange={handleChange}
                                  name="excludeFromStats"
                                  value={values.excludeFromStats}
                                  checked={values.excludeFromStats}/>
                              <label
                                  htmlFor="excludeFromStats"
                                  className="text-xs ml-2 text-gray-500 m-auto">
                                Excluír de estadísticas
                              </label>
                            </div>
                          </div>
                          <div className="flex justify-evenly">
                            <input
                                className="py-1 px-1.5 rounded-md border border-transparent bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-xs w-fit"
                                type="submit"
                                value={'Guardar'}/>
                            <input
                                className="py-1 px-1.5 rounded-md border border border-red-500 text-red-500 hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-xs w-fit"
                                type="button"
                                onClick={handleCancelBtn}
                                value={'Cancelar'}/>
                          </div>
                        </form>
                    )
                  }

                </Formik>
              </>

          )
          : (
              <div>Cargando...</div>
          )


  )
}
