import { DateRangePicker, defaultTheme, Provider } from "@adobe/react-spectrum"
import { useSearchParams } from "react-router-dom"
import moment from "moment"

function formatDateForFilter(date) {
  const day = Number(date.day) > 9 ? date.day : `0${date.day}`
  const month = Number(date.month) > 9 ? date.month : `0${date.month}`
  return `${date.year}-${month}-${day}`
}

export default function FilterByDateRange ({label}){
  const [searchParams, setSearchParams] = useSearchParams()
  const handleChange = ({ start, end }) => {
    searchParams.set('issuedAt_Gte', `${moment(formatDateForFilter(start)).utc().format('YYYY-MM-DD')}T15:00:00+00:00`)
    searchParams.set('issuedAt_Lte', `${moment(formatDateForFilter(end)).utc().format('YYYY-MM-DD')}T15:00:00+00:00`)
    setSearchParams(searchParams)
  }

  const handleClearBtn = () => {
    searchParams.delete('issuedAt_Gte')
    searchParams.delete('issuedAt_Lte')
    setSearchParams(searchParams)
  }

  return(
      <Provider theme={defaultTheme}>
        <div className="relative">
          <DateRangePicker
              label={label}
              onChange={handleChange}/>
          {
              searchParams.has('issuedAt_Gte') &&
              <div className="absolute bottom-1.5 right-10 ">
                <button onClick={handleClearBtn} className="text-gray-500">
                  X
                </button>
              </div>
          }

        </div>
      </Provider>
  )
}
