import { gql } from "@apollo/client"

const transactionCategoriesQuery = gql(`
  query {
    transactionCategories(parent_Isnull: true) {
      edges {
        node {
          id
          name
          subCategories {
            edges {
              node {
                ...SubCategoryFields
              }
            }
          }
        }
      }
    }
  }
`)

export default transactionCategoriesQuery