import { gql } from "@apollo/client"

const ImportedFilesQuery = gql(`
    query ImportedFiles (
    $first: Int,
    $type: FilesImportedFileTypeChoices,
    $status: FilesImportedFileStatusChoices,
    $created_Gte: DateTime,
    $created_Lte: DateTime,
    $after: String
) {
    importedFiles(
        first: $first,
        type: $type,
        status: $status,
        created_Gte: $created_Gte,
        created_Lte: $created_Lte,
        after: $after
    ) {
        edges {
            node {
                id
                type
                status
                file
                created
                email {
                    id
                    subject
                }
               logs {
                  edges {
                      node {
                          id
                          level
                          created
                          summary
                      }
                  }
              }
            }
        }
        pageInfo{
            hasNextPage
            endCursor
            startCursor
        }
    }
}
`)

export default ImportedFilesQuery