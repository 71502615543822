import TableContainer from "components/tables/TableContainer"
import Table from "components/tables/Table"
import THead from "components/tables/THead"
import TBody from "components/tables/TBody"
import Td from "components/tables/Td"
import Tr from "components/tables/Tr"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import types from "./TYPE_CHOICES.json"
import statuses from "./STATUS_CHOICES.json"
import NoDataToSHow from "components/NoDataToSHow"
import N1Icons from "components/icons/N1Icons"
import { Tooltip } from "react-tooltip"
import ImportedFilesErrorsLogsModal from "components/importedFiles/ImportedFilesErrorsLogsModal"
import useImportedFilesQuery from "hooks/importedFiles/useImportedFilesQuery"
import TableLoading from "components/tables/TableLoading"
import FetchMoreLine from "components/tables/FetchMoreLine"


export default function ImportedFilesTable() {
  const [showErrorsLogsModal, setShowErrorsLogsModal] = useState(true)
  const [selectedImportedFilesData, setSelectedImportedFilesData] = useState(null)

  const {data, loading, error, fetch, fetchMore, variables} = useImportedFilesQuery()

  useEffect(() => {
    fetch()
  }, [])

  const fetchMoreResult = useCallback(() => {
    fetchMore({
      variables: {
        ...variables,
        after: data.importedFiles.pageInfo.endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {

        if (!fetchMoreResult) return previousResult

        return {
          ...fetchMoreResult,
          importedFiles: {
            ...fetchMoreResult.importedFiles,
            edges: [
              ...previousResult.importedFiles.edges,
              ...fetchMoreResult.importedFiles.edges
            ]
          }
        }
      },
    })
  },[data, fetchMore])

  const handlePressErrorBtn = (node) => {
    setSelectedImportedFilesData(node)
    setShowErrorsLogsModal(true)
  }

  const handleCloseModal = () => {
    setShowErrorsLogsModal(false)
  }

  if(error){
    return (
        <div>Error al traer la data...</div>
    )
  }

  return (
      <div>
        <TableContainer>
          <Table>
            <THead>
              <tr>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Fecha
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Fuente
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Status
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Tipo
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  <div className="group inline-flex items-center gap-x-2 ">
                    <span className=" text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Descargar
                    </span>
                  </div>
                </th>
              </tr>
            </THead>
            <TBody>
              {
                loading
                    ? (
                        <TableLoading colSpan={5}/>
                    )
                    : (
                        data?.importedFiles.edges.length > 0
                            ? data?.importedFiles.edges.map(({ node }) => (
                                <React.Fragment key={node.id}>
                                  <tr>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                      {moment(node.created).format('DD-MM-YYYY')}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                      {node.email ? "Email" : "User"}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                      {
                                        node.status === "ERROR"
                                            ? <div className="text-red-500 cursor-pointer"
                                                   onClick={() => handlePressErrorBtn(node)}>{statuses[node.status]}</div>
                                            : <div>{statuses[node.status]}</div>
                                      }

                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                                      {types[node.type]}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 flex ">
                                      <a href={node.file} target="_blank" className="m-auto" data-tooltip-id={node.id}>
                                        <N1Icons icon={node.file.split('.').pop()}/>
                                        <Tooltip
                                            id={node.id}
                                            place="top"
                                            content={node.file.split('/').pop()}
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                </React.Fragment>
                            ))
                            : (
                                <Tr>
                                  <Td colSpan="6">
                                    <NoDataToSHow/>
                                  </Td>
                                </Tr>
                            )
                    )
              }
              {
                data?.importedFiles.pageInfo.hasNextPage &&
                  <TableLoading colSpan={5}/>
              }
            </TBody>
          </Table>
        </TableContainer>

        {
            selectedImportedFilesData &&
            <ImportedFilesErrorsLogsModal isOpen={showErrorsLogsModal} handleClose={handleCloseModal} data={selectedImportedFilesData}/>
        }

        <FetchMoreLine pageInfo={data?.importedFiles.pageInfo} updateFunction={fetchMoreResult}/>

      </div>
  )
}
