import { FilePond, registerPlugin } from "react-filepond"
import {useEffect, useRef, useState} from "react"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import createImportedFileMutation from "mutations/chat/CreateImportedFileMutation"
import { useMutation } from "@apollo/client"
import ImportedFilesQuery from "queries/importedFIles/ImportedFilesQuery"
import { useRecoilState } from "recoil"
import chatMessagesState from "atoms/chat/chatMessagesState"
import { Formik } from "formik"
import uuid from "react-uuid"
import DotsLoading from "components/chat/DotsLoading"
import './styles.css'
import usePusher from "contexts/pusher/usePusher"
import client from "apollo/client"

registerPlugin(FilePondPluginFileValidateType)

export default function ChatInput() {
  const [showDragFile, setShowDragFile] = useState(false)
  const [chatMessage, setChatMessage] = useRecoilState(chatMessagesState)
  const [loading, setLoading] = useState(false)
  const [createImportedFile] = useMutation(createImportedFileMutation)
  const filePondRef = useRef(null)
  const pusher = usePusher()

  useEffect(() => {
    // TODO dynamic company, Q29tcGFueU5vZGU6MQ== is the company with id 1
    const channel = pusher.subscribe('Q29tcGFueU5vZGU6MQ==')
    // TODO event names in constants file
    channel.bind('imported-file-processed', (data) => {
      // TODO would be better to have a status constants
      if (data?.status === 'SUCCESS' && data?.imported_file_id){
        client.refetchQueries({
          include: "active"
        })
      }
    })

    return () => pusher.unsubscribe('Q29tcGFueU5vZGU6MQ==')
  }, [])


  const handleSubmit = async (values, { resetForm }) => {
    if (values.chatInput !== '') {
      setChatMessage(prevMessages => [...prevMessages, { origin: 'user', msg: values.chatInput, id: uuid() }])
      resetForm()

      setLoading(true)

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL_CHAT}/api/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: values.chatInput }),
        })

        if (!response.ok) {
          console.log("Network response was not ok")
        }

        const data = await response.json()

        setChatMessage(prevMessages => [...prevMessages, { origin: 'system', msg: data.response, id: uuid() }])
        setLoading(false)
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message)
      }
    }
  }

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setShowDragFile(true)
    } else if (e.type === "dragleave") {
      setShowDragFile(false)
    }
  }

  return (
      <footer
          className=" w-full border-t border-gray-200 py-4 ">

        <div className=" mx-auto px-4 ">


          <Formik
              initialValues={{ chatInput: '' }}
              onSubmit={handleSubmit}>
            {
              ({ handleSubmit, handleChange, values }) => (
                  <form onSubmit={handleSubmit} onDragEnter={handleDrag}>
                    <div className="my-2 w-full mx-auto">
                      <div className="relative">
                        <input
                            autoComplete="off"
                            name="chatInput"
                            type="text"
                            onChange={handleChange}
                            value={values.chatInput}
                            className="p-4 block w-full border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                            placeholder="Ask me anything..."/>
                        <div className="absolute top-1/2 right-2 -translate-y-1/2">
                          <button onClick={() => setShowDragFile(!showDragFile)}
                                  type="button"
                                  className="inline-flex flex-shrink-0 justify-center items-center h-10 w-10 rounded-full text-gray-500 hover:text-gray-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all dark:hover:text-gray-200 dark:focus:ring-slate-600">
                            <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 fill="currentColor" viewBox="0 0 16 16">
                              <path fillRule="evenodd"
                                    d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"/>
                              <path
                                  d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                  </form>)
            }

          </Formik>

          {
              showDragFile &&
              <FilePond
                  name="files"
                  ref={filePondRef}
                  allowFileTypeValidation={true}
                  acceptedFileTypes={["application/zip", "application/x-zip", "application/x-zip-compressed", "multipart/x-zip", "application/pdf", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/zip"]}
                  server={{
                    process: async (fieldName, file, metadata, load, error) => {
                      const formData = new FormData()
                      formData.append('operations', JSON.stringify({
                        query: `mutation ($input: CreateImportedFileInput!) { 
                              createImportedFile(input: $input) { 
                                 importedFile { 
                                     id, 
                                     hash 
                                 } 
                              } 
                            }`, variables: {
                          input: {
                            file: null
                          }
                        }
                      }))
                      formData.append('map', JSON.stringify({
                        [fieldName]: ['variables.input.file']
                      }))
                      formData.append(fieldName, file)

                      try {
                        await createImportedFile({
                          variables: {
                            input: {
                              file: file
                            }
                          }, context: {
                            fetchOptions: {
                              body: formData,
                              method: 'POST',
                            },
                          }, refetchQueries: [{
                            query: ImportedFilesQuery
                          }],
                          onCompleted: (data) => {
                            if (data && data.createImportedFile && data.createImportedFile.importedFile) {

                              setChatMessage([
                                ...chatMessage,
                                {
                                  id: uuid(),
                                  origin: 'system',
                                  msg: `tu archivo ${data.createImportedFile.importedFile.file.split('/')[data.createImportedFile.importedFile.file.split('/').length - 1]} se ha subido con éxito`
                                }
                              ])

                              load(data.createImportedFile.importedFile.id)

                              const uploadedFileId = data.createImportedFile.importedFile.id

                              // Find and remove the file from FilePond using the ref after successful upload
                              setTimeout(() => {
                                const ItemFile = filePondRef.current.getFiles().find(file => file.serverId === uploadedFileId)

                                if (ItemFile) {

                                  filePondRef.current.removeFile(ItemFile.id)
                                }


                              }, 1600)

                            }

                          },
                          onError: () => {
                            error('Error uploading file')
                          }
                        })

                      } catch (err) {
                        error('Error uploading file')
                      }
                    }
                  }}
                  credits={false}
                  allowMultiple={true}
                  dropOnPage={true}
                  allowRevert={false}
              />

          }
        </div>
        {
            loading &&
            <DotsLoading/>
        }

      </footer>)
}
