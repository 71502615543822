
const version = '1'
const TOKEN_KEY = `N1TokenV${version}`
const USER_KEY = `N1UserV${version}`


// TODO set these in recoil and persist in localStorage
export const setToken = (token) => localStorage.setItem(TOKEN_KEY, token)
export const getToken = () => localStorage.getItem(TOKEN_KEY) || ''
export const deleteToken = () => localStorage.removeItem(TOKEN_KEY)
export const setUser = (user) => localStorage.setItem(USER_KEY, JSON.stringify(user))
export const getUser = () => {
  const user = localStorage.getItem(USER_KEY)
  return user ? JSON.parse(user) : null
}
export const deleteUser = () => localStorage.removeItem(USER_KEY)
