import { gql } from "@apollo/client"

const UpdateContactMutation = gql(`
  mutation UpdateContact ($input: UpdateContactInput!) {
    updateContact(input: $input) {
        contact {
            id
        }
    }
  }
`)

export default UpdateContactMutation