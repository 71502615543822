import Select from "react-select"
import { useQuery } from "@apollo/client"
import transactionCategoriesQuery from "queries/transactions/TransactionCategoriesQuery"

const customStyles = {
  // menu: (styles) => {
  //   return {
  //     ...styles,
  //     // TODO fix this hard coded select
  //     position: 'relative',
  //     zIndex: 500
  //   }
  // },
  option: (styles, { data }) => {
    return {
      ...styles,
      ...(data.isSubcategory && {
        paddingLeft: '2rem'
      })
    }
  }
}

export default function CategorySelect({ categoriesToExclude, selectedCategories, ...rest }) {
  const { loading, error, data } = useQuery(transactionCategoriesQuery)

  if (loading || error) return

  let options = []

  data.transactionCategories.edges.forEach(({ node }) => {
    options.push({
      value: node.id,
      label: node.name
    })

    if (node.subCategories.edges.length > 0) {
      node.subCategories.edges.forEach(({ node }) => {
        options.push({
          value: node.id,
          label: node.name,
          isSubcategory: true
        })
      })
    }
  })

  if (categoriesToExclude?.length > 0) {
    options = options.filter((option) => !categoriesToExclude.includes(option.value))
  }

  return (
      <Select
          options={options}
          styles={customStyles}
          {...{
            ...(selectedCategories?.length > 0 ? {
              value: options.filter((option) => selectedCategories.includes(option.value))
            } : {}),
            ...rest
          }}
      />
  )
}