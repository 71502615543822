import N1Modal from "components/N1Modal"
import EditContactForm from "components/contacts/EditContactForm"

export default function EditContactModal({ isOpen, handleClose, data }) {

  return (
      <N1Modal isOpen={isOpen} onClose={handleClose} name={data.name} xl>
          <EditContactForm data={data} onAfterSave={handleClose}/>
      </N1Modal>
  )
}
