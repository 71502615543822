import TableContainer from "components/tables/TableContainer"
import TableHeader from "components/tables/TableHeader"
import React, { useCallback, useContext, useEffect } from "react"
import Table from "components/tables/Table"
import THead from "components/tables/THead"
import TBody from "components/tables/TBody"
import ElectronicInvoiceRow from "components/ElectronicInvoiceRow"
import TransactionContext from "providers/TransactionProvider/TransactionContext"
import classNames from "classnames"
import ExportBtn from "components/ExportBtn"
import Td from "components/tables/Td"
import NoDataToSHow from "components/NoDataToSHow"
import Tr from "components/tables/Tr"
import useInvoicesQuery from "hooks/invoices/useInvoicesQuery"
import FetchMoreLine from "components/tables/FetchMoreLine"
import TableLoading from "components/tables/TableLoading"
import OrderByBtn from "components/transactions/OrderByBtn"


export default function InvoicesTable() {

  const transaction = useContext(TransactionContext)

  const { data, loading, fetch, fetchMore, variables } = useInvoicesQuery()

  useEffect(() => {
    fetch()
  }, [])

  const fetchMoreResult = useCallback(() => {
    fetchMore({
      variables: {
        ...variables,
        after: data.electronicInvoices.pageInfo.endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {

        if (!fetchMoreResult) return previousResult

        return {
          ...fetchMoreResult,
          electronicInvoices: {
            ...fetchMoreResult.electronicInvoices,
            edges: [
              ...previousResult.electronicInvoices.edges,
              ...fetchMoreResult.electronicInvoices.edges
            ]
          }
        }
      },
    })
  }, [data, fetchMore])


  return (
      <>
        <TableContainer>
          <TableHeader>
            <ExportBtn path={'electronic-invoices'}/>


          </TableHeader>
          <Table>
            <THead>
              <tr>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Fecha
                    </span>
                    <OrderByBtn orderBy="issuedAt"/>
                  </div>
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  Nombre
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                  Impuesto
                </th>
                <th
                    scope="col"
                    className={classNames([
                      "px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase",
                      transaction && "text-left"
                    ])}>
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Total
                    </span>
                    {/*<OrderByBtn orderBy="total"/>*/}
                  </div>
                </th>
                {
                    transaction &&
                    <th
                        scope="col"
                        className=" py-3 px-2 text-center text-xs font-medium text-gray-500 uppercase">
                      Match
                    </th>
                }

              </tr>
            </THead>
            <TBody>
              {
                loading
                    ? <TableLoading colSpan={4}/>
                    :
                    data?.electronicInvoices.edges.length === 0
                        ? (
                            <Tr>
                              <Td colSpan="6">
                                <NoDataToSHow/>
                              </Td>
                            </Tr>
                        )
                        :
                        data &&
                        data.electronicInvoices.edges.map(({ node }) => (
                            <ElectronicInvoiceRow key={node.id} invoice={node}/>
                        ))
              }
              {
                  data?.electronicInvoices.pageInfo.hasNextPage &&
                  <TableLoading colSpan={4}/>
              }
            </TBody>
          </Table>
        </TableContainer>
        <FetchMoreLine pageInfo={data?.electronicInvoices.pageInfo} updateFunction={fetchMoreResult}/>
      </>
  )
}
