import transactionCategoriesQuery from "queries/transactions/TransactionCategoriesQuery"
import { useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import selectedAssignedCategoryIdState from "atoms/categories/selectedAssignedCategoryState"
import { FiEdit2 } from "react-icons/fi"
import EditCategoryForm from "components/category/EditCategoryForm"
import classNames from "classnames"

export default function CategoryList() {
  const [selectedAssignedSubCategory, setSelectedAssignedSubCategory] = useRecoilState(selectedAssignedCategoryIdState)
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null)

  const { data, loading } = useQuery(transactionCategoriesQuery)

  useEffect(() => {
    setTimeout(() => {
      const categoryItem = document.getElementById(`${selectedAssignedSubCategory}`)
      if (categoryItem) {
        categoryItem.scrollIntoView({ behavior: 'smooth' })
      }
    }, 300)
  }, [selectedAssignedSubCategory])

  const handleCheckMarkCategory = async (node) => {
    setSelectedAssignedSubCategory(node.id)
  }

  const handleEditSubCategory = (id) => {
    if(selectedSubCategoryId){
      if(selectedSubCategoryId === id){
        setSelectedSubCategoryId(null)
      }else{
        setSelectedSubCategoryId(id)
      }
    }else{
      setSelectedSubCategoryId(id)
    }
  }

  const handleSaveBtn = () => {
    setSelectedSubCategoryId(null)
  }

  return (
      <div className=" space-y-5 ">
        {
          loading
              ? <div>Cargando...</div>
              : (
                  data?.transactionCategories?.edges.map(({ node }) => (
                      <div key={node.id}>
                        <div className=" pb-1 text-sm text-gray-800 border-b  mb-2 font-semibold">
                          {node.name}
                        </div>
                        <div className="space-y-2 ">{
                            node.subCategories &&
                            node.subCategories.edges.map(({ node }) => (
                                <div
                                    key={node.id}
                                    id={`${node.id}`}
                                    className={classNames([
                                      "flex flex-col  border p-2 shadow-sm rounded-lg text-xs group",
                                      selectedAssignedSubCategory === node.id && 'bg-blue-50'
                                    ])}>
                                  <div className="flex gap-x-1">
                                    <div className="cursor-pointer" onClick={() => {
                                      handleCheckMarkCategory(node)
                                    }}>
                                      <svg
                                          className="flex-shrink-0 h-4 w-4 rounded-full text-blue-600 hover:border border-blue-500 "
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z"
                                            fill="currentColor"
                                            fillOpacity="0.1"/>
                                        {
                                            selectedAssignedSubCategory === node.id &&
                                            <path
                                                d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z"
                                                fill="currentColor"/>
                                        }
                                      </svg>
                                    </div>
                                    <div>
                                      {node.name}
                                    </div>
                                    <div className="flex grow justify-end my-auto ">
                                      <div
                                          className="text-xs invisible group-hover:visible cursor-pointer "
                                          onClick={() => handleEditSubCategory(node.id)}>
                                        {
                                          selectedSubCategoryId === node.id
                                              ? <div className="text-gray-700">X</div>
                                              : <FiEdit2 className="text-gray-500"/>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  {
                                      selectedSubCategoryId === node.id &&
                                      <div
                                          className={classNames([
                                              "w-full overflow-hidden transition-all duration-300 transform ",
                                              selectedSubCategoryId === node.id && "translate-y-0",
                                              selectedSubCategoryId !== node.id && "translate-y-full hidden",
                                          ])}
                                          aria-labelledby="hs-basic-no-arrow-heading-two">
                                        <div className="p-3">

                                          <EditCategoryForm
                                              key={node.id}
                                              subCategoryData={node}
                                              handleSaveBtn={handleSaveBtn}
                                          />

                                        </div>
                                      </div>
                                  }

                                </div>
                            ))
                        }</div>
                      </div>

                  ))
              )


        }
      </div>
  )
}
