import {gql} from "@apollo/client"

const UpdateTransactionCategory = gql(`
    mutation UpdateTransactionCategory($input: UpdateTransactionCategoryInput!) {
      updateTransactionCategory(input: $input) {
        transactionCategory {
          id
          name
          parent {
              id
              name
          }
        }
      }
    }
`)

export default UpdateTransactionCategory