import SearchForm from "components/transactions/SearchForm"
import { Suspense } from "react"
import DateRangeFilter from "components/transactions/DateRangeFilter"
import ExcludeIncomeSwitch from "components/transactions/ExcludeIncomeSwitch"
import TransactionCategoryFilter from "components/transactions/TransactionCategoryFilter"
import MatchesSelect from "components/transactions/MatchesSelect"
import ExcludeCategorySelect from "components/transactions/ExcludeCategorySelect"

export default function FiltersBar (){

  return(
      <div className="flex flex-col p-4 bg-white w-56 rounded-xl border h-fit gap-y-5">

        <div>
          <SearchForm/>
        </div>
        <Suspense>
          <DateRangeFilter label={'Filtrar por rango de fecha'}/>
        </Suspense>
        <div className="w-full flex ">
          <ExcludeIncomeSwitch/>
        </div>
        <div>
          <div className="block text-sm font-medium mb-2 dark:text-white">Categorías</div>
          <TransactionCategoryFilter />
        </div>
        <div>
          <div className="block text-sm font-medium mb-2 dark:text-white">Excluir Categorías</div>
          <ExcludeCategorySelect />
        </div>
        <div>
          <div className="block text-sm font-medium mb-2 dark:text-white">Con / Sin factura</div>
          <MatchesSelect/>
        </div>
      </div>
  )
}
