import { ReactNode } from 'react'
import Logo from 'components/main/Logo'
import InLineLink from 'components/text/InLineLink'

export default function AuthBox({ title, subtitle, subtitleLinkTo, subtitleLinkText, children }) {
  return (
      <div
          className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700 relative">
        <div className="absolute w-full -top-8">
          <div className="flex w-16 h-16 items-center justify-center rounded-full bg-white border border-gray-200 shadow-2xl mx-auto dark:bg-gray-800 dark:border-gray-700">
            <Logo color="blue" className="h-9"/>
          </div>
        </div>
        <div className="p-4 pt-14 sm:p-7 sm:pt-14">
          <div className="text-center">
            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">{title}</h1>
            {
                subtitle &&
                <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  {subtitle}
                  {
                      (subtitleLinkText && subtitleLinkTo) &&
                      <>
                        &nbsp;
                        <InLineLink to={subtitleLinkTo}>
                          {subtitleLinkText}
                        </InLineLink>
                      </>
                  }
                </p>
            }
          </div>
          <div className="mt-5">
            {children}
          </div>
        </div>
      </div>
  )
}
