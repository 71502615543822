import React from "react"
import SearchByNameFilter from "components/invoices/SearchByNameFilter"
import FilterByDateRange from "components/invoices/FilterByDateRange"

export default function InvoicesFiltersSideBar (){
  return(
      <div className="flex flex-col p-4 bg-white w-56 rounded-xl border h-fit gap-y-5">
        <div>
          <div className="block text-sm font-medium mb-2 dark:text-white">
            Tipo:
          </div>
          <SearchByNameFilter/>
        </div>
        <div>
          <div className="block text-sm font-medium mb-2 dark:text-white">
            Fecha:
          </div>
          <FilterByDateRange/>
        </div>
      </div>
  )
}
