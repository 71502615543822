import { Link } from "react-router-dom"
import classNames from "classnames"
import { useRecoilValue } from "recoil"
import chatIsOpenState from "atoms/main/chatIsOpenState"
import Logo from "components/main/Logo"
import UserIcon from "components/main/header/UserIcon"
import NavMenu from "components/main/header/NavMenu"

export default function Header() {
  const chatIsOpen = useRecoilValue(chatIsOpenState)

  return (
      <header
          className={classNames([
            "sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48] w-full bg-white border-b text-sm py-2.5 sm:py-4 ",
            "transition-all duration-300",
            chatIsOpen && "lg:pr-[410px]"
          ])}>
        <nav className="flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8" aria-label="Global">
          <div className="px-6">
            <Link to="/" aria-label="N1">
              <Logo color="blue" className="w-8 h-8"/>
            </Link>
          </div>
          <div className="w-full flex items-center  justify-between">
            <NavMenu/>
            <UserIcon/>
          </div>
        </nav>
      </header>
  )
}