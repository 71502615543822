import { DOC_PDF, DOC_XLS, DOC_ZIP, DOC_XLSX } from "components/icons/N1Icons/const /icons"
import { GrDocumentDownload, GrDocumentZip } from "react-icons/gr"
import { BsFileEarmarkPdf, BsFiletypeXls } from "react-icons/bs"

function IconSelect({icon}) {
  switch (icon) {
    case DOC_PDF:
      return <BsFileEarmarkPdf/>
    case DOC_ZIP:
      return <GrDocumentZip/>
    case DOC_XLS:
      return <BsFiletypeXls/>
    case DOC_XLSX:
      return <BsFiletypeXls/>
    default:
      return <GrDocumentDownload/>

  }
}
export default function N1Icons ({ icon }){

  return(
      <span>
        <IconSelect icon={icon}/>
      </span>
  )
}
