import React from "react"
import { useSearchParams } from "react-router-dom"
import Select from "react-select"

export default function MatchesSelect() {
  const [searchParams, setSearchParams] = useSearchParams()

  const options = [
    { value: false, label: 'Con Factura' },
    { value: true, label: 'Sin Factura' },
  ]

  const handleChange = option => {
    if (option) {
      searchParams.set('matches_Isnull', option.value)
    } else {
      searchParams.delete('matches_Isnull')
    }
    setSearchParams(searchParams)
  }

  return (

      <Select
          value={searchParams.has('matches_Isnull') ? searchParams.get('matches_Isnull') === 'true' ? options[1] : options[0] : ''}
          options={options}
          onChange={handleChange}
          isClearable
      />
  )
}