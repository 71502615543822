import ImportedFilesTable from "components/importedFiles/ImportedFilesTable"
import FiltersSideBar from "components/importedFiles/FiltersSideBar"

export default function ImportedFilesPage() {
  return (
      <div className="flex py-5 gap-x-5 h-full">
        <FiltersSideBar/>
        <div className=" w-full overflow-y-auto">
          <ImportedFilesTable/>
        </div>
      </div>
  )
}
