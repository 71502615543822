import classNames from "classnames"
import { useRecoilValue } from "recoil"
import chatIsOpenState from "atoms/main/chatIsOpenState"

export default function Chat  ({children}){
  const isOpen = useRecoilValue(chatIsOpenState)

  return(
      <div
          id="chat-sidebar"
          className={classNames([
            "flex flex-col h-screen transition-all duration-300 transform fixed top-0 right-0 bottom-0 z-[60] w-[400px] bg-white border-l border-gray-200 overflow-y-auto scrollbar-y lg:block lg:left-auto lg:bottom-0 ",
            !isOpen && "translate-x-full hidden",
            isOpen && "translate-x-0",
          ])}>

        {children}
      </div>
  )
}
