import { BsFillChatDotsFill } from "react-icons/bs"
import { useRecoilState } from "recoil"
import chatIsOpenState from "atoms/main/chatIsOpenState"

export default function ChatBtn  (){
  const [isOpen, setIsOpen] = useRecoilState(chatIsOpenState)

  const toggle = () => setIsOpen(!isOpen)
  
  return(
      <div className="absolute bottom-10 right-10 z-50">
        <button
            type="button"
            className="py-4 px-4 inline-flex justify-center items-center gap-2 rounded-full border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800 shadow"
            data-hs-overlay="#hs-overlay-right"
            onClick={toggle}>
          <BsFillChatDotsFill className="text-2xl"/>
        </button>
      </div>
  )
}
