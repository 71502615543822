import { gql } from "@apollo/client"

const electronicInvoicesQuery = gql(`
   query ElectronicInvoices ( $name: String, $after: String, $orderBy: String, $issuedAt_Gte: DateTime, $issuedAt_Lte: DateTime) {
    electronicInvoices ( contact_LegalName_Icontains: $name, after: $after, orderBy: $orderBy, issuedAt_Gte: $issuedAt_Gte, issuedAt_Lte: $issuedAt_Lte) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        edges {
            node {
                id
                vat
                total
                issuedAt
                contact {
                    id
                    idNumber
                    idType
                    name
                }
            }
        }
    }
}
`)

export default electronicInvoicesQuery