import Td from "components/tables/Td"
import Tr from "components/tables/Tr"

export default function TableLoading ({colSpan}){
  return(
      <Tr>
        <Td colSpan={colSpan}>
          <div className="w-full flex">
            <div
                className=" m-auto animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                role="status" aria-label="loading">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </Td>
      </Tr>
  )
}
