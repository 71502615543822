import './index.css'
import router from "router"
import { RouterProvider } from "react-router-dom"
import React, {useEffect} from "react"
import changeFavicon from "utils/changeFavicon"


function App() {
  useEffect(() => {
    import('preline')

    const isDark = window.matchMedia("(prefers-color-scheme: dark)")

    changeFavicon(isDark);

    isDark.addEventListener('change', changeFavicon);

    // Cleanup listener on component unmount
    return () => {
      isDark.removeEventListener('change', changeFavicon);
    };

  }, [])

  return (
      <>
        <RouterProvider router={router}/>
      </>
  )
}

export default App
