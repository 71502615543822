import Currency from "components/currencies/Currency"
import React from "react"

export default function HeaderModal({ data }) {

  return (
      <div className="mb-3 px-5 py-2  flex justify-between m-auto bg-gray-200 rounded-lg">
        <div>
          <span className="block text-xs capitalize text-gray-500">Fecha:</span>
          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">{data.transaction.date}</span>
        </div>

        <div>
          <span className="block text-xs capitalize text-gray-500">Transacción:</span>
          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">{data.transaction.name}</span>
        </div>
        <div>
          <span className="block text-xs capitalize text-gray-500">Categoria:</span>
          <span
              className="block text-sm font-medium uppercase text-gray-800 dark:text-gray-200">
                  {
                    data.transaction.category
                        ? data.transaction.category.name
                        :''
                  }
                </span>
        </div>

        <div>
          <span className="block text-xs capitalize text-gray-500">Valor:</span>
          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
            <Currency amount={data.transaction.amount}/>
          </span>
        </div>
      </div>
  )
}
