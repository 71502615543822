import { useCallback, useEffect } from "react"
import TransactionModal from "components/TransactionModal"
import OrderByBtn from "components/transactions/OrderByBtn"
import TableContainer from "components/tables/TableContainer"
import TableHeader from "components/tables/TableHeader"
import Table from "components/tables/Table"
import THead from "components/tables/THead"
import TBody from "components/tables/TBody"
import Tr from "components/tables/Tr"
import Td from "components/tables/Td"
import TransactionsTableRow from "components/transactions/TransactionsTableRow"
import { useRecoilValue } from "recoil"
import selectedTransactionState from "atoms/transactions/selectedTransactionState"
import Currency from "components/currencies/Currency"
import ExportBtn from "components/ExportBtn"
import NoDataToSHow from "components/NoDataToSHow"
import useTransactionsQuery from "hooks/transactions/useTransactionsQuery"
import TableLoading from "components/tables/TableLoading"
import FetchMoreLine from "components/tables/FetchMoreLine"


export default function TransactionsTable() {
  const selectedTransaction = useRecoilValue(selectedTransactionState)

  const { fetch, loading: transactionDataLoading, error, data, variables, fetchMore } = useTransactionsQuery()

  useEffect(() => {
    fetch()
  }, [])

  const fetchMoreResult = useCallback(() => {
    fetchMore({
      variables: {
        ...variables,
        after: data.transactions.pageInfo.endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {

        if (!fetchMoreResult) return previousResult

        return {
          ...fetchMoreResult,
          transactions: {
            ...fetchMoreResult.transactions,
            edges: [
              ...previousResult.transactions.edges,
              ...fetchMoreResult.transactions.edges
            ]
          }
        }
      },
    })
  },[data, fetchMore])

  if (error)
    return <div>Ocurrió un error al traer la data</div>

  return (
      <>
        <TableContainer>
          <TableHeader>
            <ExportBtn path={'transactions'}/>
            <div className="flex gap-3">
              {
                transactionDataLoading
                    ? <></>
                    : (
                        <>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            Transacciones: <span
                              className="font-semibold text-gray-800 dark:text-gray-200">{data?.transactions.totalCount}</span>
                          </p>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            -
                          </p>
                          <p className="text-sm text-gray-600 dark:text-gray-400">
                            Total:
                            <span className="font-semibold text-gray-800 dark:text-gray-200 pl-1">

                              {
                                data && data.transactions && data.transactions.totalAmount
                                    ? <Currency amount={data.transactions.totalAmount}/>
                                    : "0"
                              }

                            </span>
                          </p>
                        </>
                    )
              }
            </div>
          </TableHeader>
          <Table>
            <THead>
              <tr>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Fecha
                    </span>
                    <OrderByBtn orderBy="date"/>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span
                        className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Nombre
                    </span>
                    <OrderByBtn orderBy="name"/>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Categoría
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-center">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Factura
                    </span>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 text-right">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Valor
                    </span>
                    <OrderByBtn orderBy="amount"/>
                  </div>
                </th>

                {/*<th scope="col" className="px-6 py-3 text-right"></th>*/}
              </tr>
            </THead>

            <TBody>
              {
                transactionDataLoading
                    ?
                    <TableLoading colSpan={5}/>
                    :
                    data?.transactions.edges.length === 0
                        ?
                        <Tr>
                          <Td colSpan="5">
                            <NoDataToSHow/>
                          </Td>
                        </Tr>
                        :
                        (data?.transactions.edges.map(({ node }) => (
                            <TransactionsTableRow key={node.id} node={node}/>
                        )))
              }

              {
                data?.transactions?.pageInfo.hasNextPage &&
                  <TableLoading colSpan={5}/>
              }



            </TBody>
          </Table>
        </TableContainer>

        {
            (selectedTransaction) &&
            <>
              <TransactionModal
                  transactionId={selectedTransaction}/>
            </>
        }
        <FetchMoreLine updateFunction={fetchMoreResult} pageInfo={data?.transactions?.pageInfo}/>

      </>
  )
}
