import { useSearchParams } from "react-router-dom"

export default function ExcludeIncomeSwitch() {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleCheckedChange = (e) => {
    if(e.target.checked) {
      searchParams.set('amount_Lt', "0")
    }else{
      searchParams.delete('amount_Lt')
    }
    setSearchParams(searchParams)
  }

  return (
      <>
        <div className="flex items-center">
          <label
              htmlFor="hs-basic-with-description-checked"
              className="text-sm text-sm font-medium mr-3 dark:text-gray-400">
            Excluir Ingresos
          </label>
          <input
              onChange={handleCheckedChange}
              type="checkbox"
              id="hs-basic-with-description-checked"
              className="relative shrink-0 w-[3.25rem] h-7 bg-gray-100 checked:bg-none checked:bg-blue-600 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-blue-600 dark:focus:ring-offset-gray-800 before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200"
              checked={searchParams.has('amount_Lt')}/>
        </div>
      </>
  )
}
