import { ErrorMessage, Field, Form, Formik } from "formik"
import personTypes from './PERSON_TYPES_CHOICES.json'
import idTypes from './ID_TYPES_CHOICES.json'
import IdTypeSelect from "components/contacts/IdTypeSelect"
import PersonTypeSelect from "components/contacts/PersonTypeSelect"
import { useMutation } from "@apollo/client"
import UpdateContactMutation from "mutations/contacts/UpdateContactMutation"
import ContactsQuery from "queries/contacts/ContactsQuery"
import Loading from "components/Loading"
import * as Yup from "yup"
import classNames from "classnames"
import CreateContactMutation from "mutations/contacts/CreateContactMutation"


export default function EditContactForm({ data, onAfterSave }) {
  const [updateContact, { loading: loadingUpdateContact }] = useMutation(UpdateContactMutation)
  const [createContact, { loading: loadingCreateContact }] = useMutation(CreateContactMutation)

  const {
    firstName,
    lastName,
    legalName,
    idNumber,
    idType,
    isClient,
    isSupplier,
    whitelistedSupplier,
    personType,
    addressLine1,
    addressLine2,
    email,
    phone,
    postalCode
  } = data

  const initialValues = {
    legalName: legalName ? legalName : '',
    firstName: firstName ? firstName : '',
    lastName: lastName ? lastName : '',
    idNumber: idNumber ? idNumber : '',
    idType: {
      value: idType,
      label: idTypes[idType]
    },
    personType: {
      value: personType,
      label: personTypes[personType]
    },
    isClient: isClient ? isClient : false,
    isSupplier: isSupplier ? isSupplier : false,
    whitelistedSupplier: whitelistedSupplier ? whitelistedSupplier : false,
    addressLine1: addressLine1 ? addressLine1 : '',
    addressLine2: addressLine2 ? addressLine2 : '',
    email: email ? email : '',
    phone: phone ? phone : '',
    postalCode: postalCode ? postalCode : ''
  }

  const validationSchema = Yup.object({
    legalName: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    isClient: Yup.bool(),
    isSupplier: Yup.bool(),
    whitelistedSupplier: Yup.bool(),
    addressLine1: Yup.string(),
    addressLine2: Yup.string(),
    email: Yup.string(),
    phone: Yup.number(),
    postalCode: Yup.string(),
    idType: Yup.object().shape({ value: Yup.string().required('Este campo es requerido'), label: Yup.string().required('Este campo es requerido')}).required('Este campo es requerido'),
    idNumber: Yup.number().required('Este campo es requerido'),
    personType: Yup.object().shape({ value: Yup.string().required('Este campo es requerido'), label: Yup.string().required('Este campo es requerido')}).required('Este campo es requerido'),
  })

  const handleSubmit = (values) => {
    let newValues = { ...values }
    newValues.idType = newValues.idType.value
    newValues.personType = newValues.personType.value

    for (let key in newValues) {
      if (newValues[key] === null || newValues[key] === "") {
        delete newValues[key]
      }
    }

    if (Object.keys(data).length  > 0){
      updateContact({
        variables: {
          input: { ...newValues, id: data.id }
        },
        refetchQueries: [
          {
            query: ContactsQuery
          }
        ],
        onCompleted() {
          onAfterSave()
        }
      })
    }else {
      createContact({
        variables: {
          input: { ...newValues }
        },
        refetchQueries: [
          {
            query: ContactsQuery
          }
        ],
        onCompleted() {
          onAfterSave()
        }
      })

    }
  }

  return (
      <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}>
        {
          ({ values, handleChange, handleSubmit, errors }) => (
              <Form>
                <div className="p-5">

                  <div className="grid grid-cols-2 gap-5 ">

                    {
                      (personType === 'LEGAL' || values.personType.value === 'LEGAL')
                          ? (
                              <div className="col-span-2">
                                <label
                                    htmlFor="input-label"
                                    className="block text-sm font-medium mb-2 dark:text-white">
                                  Nombre:
                                </label>
                                <input
                                    value={values.legalName}
                                    name="'legalName"
                                    type="text"
                                    className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                    placeholder="Nombre Legal"
                                    onChange={handleChange}
                                />
                              </div>

                          )
                          : (
                              <>
                                <div className="w-full">
                                  <label htmlFor="input-label"
                                         className="block text-sm font-medium mb-2 dark:text-white">
                                    Primer Nombre:
                                  </label>

                                  <input
                                      value={values.firstName}
                                      name="firstName"
                                      type="text"
                                      className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                      placeholder="Nombre"
                                      onChange={handleChange}/>
                                </div>
                                <div className="w-full">
                                  <label htmlFor="input-label"
                                         className="block text-sm font-medium mb-2 dark:text-white">
                                    Apellido:
                                  </label>
                                  <input
                                      value={values.lastName}
                                      name="lastName"
                                      type="text"
                                      className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                      placeholder="Apellido"
                                      onChange={handleChange}/>
                                </div>
                              </>
                          )
                    }
                    <div>
                      <label htmlFor="input-label"
                             className="block text-sm font-medium mb-2 dark:text-white">
                        Numero de documento:
                      </label>
                      <input
                          value={values.idNumber}
                          name="idNumber"
                          type="number"
                          className={classNames([
                            "py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400",
                            errors.idNumber && 'border-red-600'
                          ])}
                          placeholder="Documento / Nit"
                          onChange={handleChange}/>
                      <ErrorMessage name="idNumber" className="text-xs text-red-400" component="div"/>
                    </div>
                    <div className="w-full">
                      <label htmlFor="input-label"
                             className="block text-sm font-medium mb-2 dark:text-white">
                        Tipo de Documento:
                      </label>
                      <Field
                          name="idType"
                          component={IdTypeSelect}/>
                      <div className="text-xs text-red-400">{errors.idType?.value}</div>

                    </div>
                    <div className="w-full">
                      <label htmlFor="input-label"
                             className="block text-sm font-medium mb-2 dark:text-white">
                        Tipo de Persona:
                      </label>
                      <Field
                          name="personType"
                          component={PersonTypeSelect}/>
                      <div className="text-xs text-red-400">{errors.personType?.value}</div>
                    </div>
                    <div>
                      <label htmlFor="input-label"
                             className="block text-sm font-medium mb-2 dark:text-white">
                        Dirección:
                      </label>
                      <input
                          value={values.addressLine1}
                          name="addressLine1"
                          type="text"
                          className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                          placeholder="Dirección"
                          onChange={handleChange}/>
                    </div>
                    <div>
                      <label htmlFor="input-label"
                             className="block text-sm font-medium mb-2 dark:text-white">
                        Dirección complementaria:
                      </label>
                      <input
                          value={values.addressLine2}
                          name="addressLine2"
                          type="text"
                          className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                          placeholder="Dirección complementaria"
                          onChange={handleChange}/>
                    </div>
                    <div>
                      <label htmlFor="input-label"
                             className="block text-sm font-medium mb-2 dark:text-white">
                        Zip code:
                      </label>
                      <input
                          value={values.postalCode}
                          name="postalCode"
                          type="text"
                          className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                          placeholder="Zip code"
                          onChange={handleChange}/>
                    </div>
                    <div>
                      <label htmlFor="input-label"
                             className="block text-sm font-medium mb-2 dark:text-white">
                        Correo:
                      </label>
                      <input
                          value={values.email}
                          name="email"
                          type="text"
                          className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                          placeholder="Correo"
                          onChange={handleChange}/>
                    </div>
                    <div>
                      <label htmlFor="input-label"
                             className="block text-sm font-medium mb-2 dark:text-white">
                        Teléfono:
                      </label>
                      <input
                          value={values.phone}
                          name="phone"
                          type="text"
                          className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                          placeholder="Teléfono"
                          onChange={handleChange}/>
                    </div>

                    <div className="col-span-2 flex gap-x-3 w-full justify-center">
                      <div>
                        <label
                            htmlFor="excludeFromStats"
                            className="text-sm mr-3 text-gray-500 m-auto">
                          Cliente
                        </label>
                        <input
                            type="checkbox"
                            className=" border-gray-200 rounded text-blue-600 focus:ring-blue-500 "
                            name="isClient"
                            onChange={handleChange}
                            value={values.isClient}
                            checked={values.isClient}/>
                      </div>
                      <div>
                        <label
                            htmlFor="excludeFromStats"
                            className="text-sm mr-3 text-gray-500 m-auto">
                          Proveedor
                        </label>
                        <input
                            type="checkbox"
                            className=" border-gray-200 rounded text-blue-600 focus:ring-blue-500 "
                            name="isSupplier"
                            onChange={handleChange}
                            value={values.isSupplier}
                            checked={values.isSupplier}/>
                      </div>
                      <div>
                        <label
                            htmlFor="excludeFromStats"
                            className="text-sm mr-3 text-gray-500 m-auto">
                          White Listed
                        </label>
                        <input
                            type="checkbox"
                            className=" border-gray-200 rounded text-blue-600 focus:ring-blue-500 "
                            name="whitelistedSupplier"
                            onChange={handleChange}
                            value={values.whitelistedSupplier}
                            checked={values.whitelistedSupplier}/>
                      </div>
                    </div>
                    <div className="col-span-2 flex ">
                      <button
                          onClick={handleSubmit}
                          type="button"
                          className="m-auto w-24 h-11 py-3 px-4 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
                        {
                          (loadingUpdateContact || loadingCreateContact) ? <Loading/> : 'Guardar'
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
          )
        }
      </Formik>
  )
}
