import N1SideBarHelper from "components/N1SideBarHelper"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import showEditCategorySidebarState from "atoms/transactions/showEditCategorySidebarState"
import Header from "components/N1SideBarHelper/components/Header"
import selectedTransactionIdState from "atoms/transactions/selectedTransactionState"
import { useFragment } from "@apollo/client"
import TransactionFragment from "queries/transactions/TransactionFragment"
import BtsConfirmationBar from "components/category/BtsConfirmationBar"
import selectedAssignedCategoryIdState from "atoms/categories/selectedAssignedCategoryState"
import React from "react"
import CategoryList from "components/category/CategoryList"

export default function EditCategorySideBar() {
  const [showEditCategorySidebar, setShowEditCategorySidebar] = useRecoilState(showEditCategorySidebarState)
  const setSelectedTransactionId = useSetRecoilState(selectedTransactionIdState)
  const SetSelectedAssignedCategoryId = useSetRecoilState(selectedAssignedCategoryIdState)
  const selectedTransactionId = useRecoilValue(selectedTransactionIdState)

  const {  data } = useFragment({
    fragment: TransactionFragment,
    fragmentName: 'TransactionFields',
    from: {
      __typename: "TransactionNode",
      id: selectedTransactionId,
    }
  })

  const handleClose = () => {
    setShowEditCategorySidebar(false)
    SetSelectedAssignedCategoryId(null)
    setSelectedTransactionId(null)
  }


  return (
      <N1SideBarHelper isOpen={showEditCategorySidebar}>
        <div className="flex flex-col h-full">
          <Header onClose={handleClose} name={data?.name}/>
          <div className=" p-5 overflow-y-auto grow ">
            <CategoryList/>
          </div>
          <div className="py-5 border-t bg-white">
            <BtsConfirmationBar transactionData={data}/>
          </div>
        </div>
      </N1SideBarHelper>
  )
}
