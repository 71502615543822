import { gql } from "@apollo/client"

const ProductsQuery = gql(`
  query Products ($after: String) {
    products ( after: $after ) {
        edges {
            node {
                id
                name
                stock
                sku
            }
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
        }
    }
  }
`)

export default ProductsQuery