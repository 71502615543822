import N1Modal from "components/N1Modal"
import TableContainer from "components/tables/TableContainer"
import THead from "components/tables/THead"
import TBody from "components/tables/TBody"
import Tr from "components/tables/Tr"
import React from "react"
import Table from "components/tables/Table"
import moment from "moment/moment"

export default function ImportedFilesErrorsLogsModal({ isOpen, handleClose, data }) {

  return (
      <N1Modal isOpen={isOpen} name={data.file.split('/').pop()} onClose={handleClose}>
        <div className="p-3">
          {
              data.logs &&
              <TableContainer>
                <Table>
                  <THead>
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="group inline-flex items-center gap-x-2">
                          <span
                              className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Fecha
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-left">
                        <div className="group inline-flex items-center gap-x-2">
                          <span
                              className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                            Descripción
                          </span>
                        </div>
                      </th>
                    </tr>
                  </THead>
                  <TBody>
                    {
                      data.logs.edges.map(({ node }) => (
                          <Tr key={node.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                              {moment(node.created).format('DD-MM-YYYY')}
                            </td>
                            <td className="px-6 py-4 text-sm font-medium text-gray-800 max-w-xl ">
                              {node.summary}
                            </td>
                          </Tr>
                      ))
                    }
                  </TBody>
                </Table>
              </TableContainer>
          }

        </div>

      </N1Modal>
  )
}
