import { gql } from "@apollo/client"

const deleteMatchMutation = gql(`
  mutation DeleteMatch($input: DeleteMatchInput!) {
    deleteMatch(input: $input) {
      deleted
    }
  }
`)

export default deleteMatchMutation