import { useEffect, useState } from "react"
import {useSearchParams} from "react-router-dom"
import { GrFormClose } from "react-icons/gr"

export default function SearchForm() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState(searchParams.has('name_Icontains') ? searchParams.get('name_Icontains') : '')

  const handleSubmit = event => {
    event.preventDefault()
    searchParams.set('name_Icontains', value)
    setSearchParams(searchParams)
  }

  const handleChange = event => setValue(event.target.value)

  const handleClearInputBtn = () => {
    searchParams.delete('name_Icontains')
    setSearchParams(searchParams)
    setValue('')
  }

  return (
      <form className="sm:col-span-1" onSubmit={handleSubmit}>
        <label htmlFor="hs-as-table-product-review-search" className="sr-only">Search</label>
        <div className="relative">
          <input
              type="text" id="hs-as-table-product-review-search"
              name="hs-as-table-product-review-search"
              className="py-2 px-3 pl-11 block w-full border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
              placeholder="Buscar"
              value={value}
              onChange={handleChange}/>
          {
            value !== '' &&
              <button type="button" onClick={handleClearInputBtn} className="absolute inset-y-0 right-5">
                <GrFormClose/>
              </button>
          }

          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
            <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="currentColor" viewBox="0 0 16 16">
              <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
          </div>
        </div>
      </form>
  )
}