import { useQuery } from "@apollo/client"
import ProductsQuery from "queries/products/ProductsQuery"
import TableContainer from "components/tables/TableContainer"
import Table from "components/tables/Table"
import THead from "components/tables/THead"
import TBody from "components/tables/TBody"
import Tr from "components/tables/Tr"
import Td from "components/tables/Td"
import React, { useCallback } from "react"
import NoDataToSHow from "components/NoDataToSHow"
import TableHeader from "components/tables/TableHeader"
import ExportBtn from "components/ExportBtn"
import TableLoading from "components/tables/TableLoading"
import FetchMoreLine from "components/tables/FetchMoreLine"

export default function ProductsTable() {
  const { data, loading, fetchMore } = useQuery(ProductsQuery)

  const fetchMoreResult = useCallback(() => {
    fetchMore({
      variables: {
        after: data.products.pageInfo.endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {

        if (!fetchMoreResult) return previousResult

        return {
          ...fetchMoreResult,
          products: {
            ...fetchMoreResult.products,
            edges: [
              ...previousResult.products.edges,
              ...fetchMoreResult.products.edges
            ]
          }
        }
      },
    })
  },[data, fetchMore])

  return (
      <>
        <TableContainer>
          <TableHeader>
            <ExportBtn path={'products-history'}/>
          </TableHeader>
          <Table>
            <THead>
              <Tr>
                <Td>
                  Nombre
                </Td>
                <Td>
                  Stock
                </Td>
                <Td>
                  Sku
                </Td>
              </Tr>

            </THead>
            <TBody>
              {
                loading
                    ? (
                        <TableLoading colSpan={3}/>
                    )
                    : (
                        data.products.edges.length > 0
                            ? (
                                data.products.edges.map(({ node }) => (
                                    <Tr key={node.id}>
                                      <Td>
                                        {node.name}
                                      </Td>
                                      <Td>
                                        {node.stock}
                                      </Td>
                                      <Td>
                                        {node.sku}
                                      </Td>
                                    </Tr>
                                ))
                            )
                            : (
                                <Tr>
                                  <Td colSpan="6">
                                    <NoDataToSHow/>
                                  </Td>
                                </Tr>
                            )
                    )
              }
              {
                  data?.products.pageInfo.hasNextPage &&
                  <TableLoading colSpan={3}/>
              }
            </TBody>
          </Table>
        </TableContainer>

        <FetchMoreLine pageInfo={data?.products.pageInfo} updateFunction={fetchMoreResult}/>
      </>
  )
}
