import { useSearchParams } from "react-router-dom"
import React, { useState } from "react"
import { GrFormClose } from "react-icons/gr"

export default function SearchByNameFilter (){
  const [searchParams, setSearchParams] = useSearchParams()
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleSearchForm = (e) => {
    e.preventDefault()
    searchParams.set('name', inputValue)
    setSearchParams(searchParams)
  }

  const handleClearInputBtn = () => {
    searchParams.delete('name')
    setInputValue('')
    setSearchParams(searchParams)
  }
  return(
      <div className="relative max-w-xs">
        <form onSubmit={handleSearchForm}>
          <input
              value={inputValue}
              type="text"
              name="hs-table-search"
              id="hs-table-search"
              className="p-3 pl-10 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
              placeholder="Empresa"
              onChange={handleInputChange}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
            <svg className="h-3.5 w-3.5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16"
                 height="16" fill="currentColor" viewBox="0 0 16 16">
              <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
          </div>
          {
              inputValue !== '' &&
              <button type="button" onClick={handleClearInputBtn} className="absolute inset-y-0 right-5">
                <GrFormClose/>
              </button>
          }
        </form>
      </div>
  )
}
