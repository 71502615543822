import Logo from "components/main/Logo"
import classNames from "classnames"

export default function ChatMessageLogo({ type }) {
  return (
      <div className={classNames([
        'flex border rounded-full h-[2.375rem] w-[2.375rem]',
        type !== 'user'
            ? 'border-blue-500 bg-blue-600 -ml-2 p-2'
            : 'border-gray-600 bg-gray-600'
      ])}>
        {
          type !== 'user'
              ?
              <Logo color="white" className="w-5 h-5"/>
              :
              <span className="text-sm font-medium text-white m-auto">AZ</span>
        }
      </div>
  )
}
