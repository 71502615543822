import 'filepond/dist/filepond.min.css'
import ChatHeader from "components/chat/ChatHeader"
import ChatInput from "components/chat/ChatInput"
import ChatMessages from "components/chat/ChatMessages"
import Chat from "components/chat/Chat"

export default function ChatSideBar() {

  return (
      <Chat>
        <div className="h-full flex flex-col">
          <ChatHeader/>
          <ChatMessages/>
          <ChatInput/>
        </div>
      </Chat>
  )
}
