import { gql } from "@apollo/client"

const categorizeSimilarTransactionsMutation = gql(`
    mutation CategorizeSimilarTransactions($input: CategorizeSimilarTransactionsInput!) {
      categorizeSimilarTransactions(input: $input) {
        success
        transactionsCount
       }
    }
`)

export default categorizeSimilarTransactionsMutation