import { useSearchParams } from "react-router-dom"
import StatusesSelect from "components/importedFiles/StatusesSelect"

export default function ImportedFilesStatusesSelect (){
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChange = (option) => {
    if(option){
      searchParams.set('status', option.value)
    }else{
      searchParams.delete('status')
    }
    setSearchParams(searchParams)
  }

  return(
      <StatusesSelect
          selectedStatus={searchParams.getAll('status')}
          isClearable
          onChange={handleChange}
      />
  )
}
