import idTypes from './ID_TYPES_CHOICES.json'
import Select from "react-select"

let options = []

for (const idType in idTypes) {
  options.push({ value: idType, label: idTypes[idType] })
}

export default function IdTypeSelect({ field, form, ...rest }) {
  const handleChange = option => {
    form.setFieldValue(field.name, option)
  }

  return (
      <Select
          options={options}
          name={field.name}
          onChange={handleChange}
          value={field.value}
          {
            ...rest
          }>
      </Select>
  )
}
