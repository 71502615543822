import Currency from "components/currencies/Currency"
import React from "react"
import moment from "moment/moment"

export default function MatchesTable({ data }) {

  return (
      <>
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead>
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Fecha
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Nombre</th>
                    <th scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Impuesto
                    </th>
                    <th scope="col"
                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Total
                    </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {
                    data.transaction.matches.edges.map( (match, i) => (
                          <tr key={i}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                              {moment(match.node.contentObject.issuedAt).format('DD-MM-YYYY')}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                              {match.node.contentObject.contact.legalName}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                              <Currency amount={match.node.contentObject.vat}/>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <Currency amount={match.node.contentObject.total}/>
                            </td>
                          </tr>
                    ) )
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
