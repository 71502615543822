import React from 'react'
import { useField } from 'formik'
import classNames from 'classnames'

const TextField = ({ label, type = 'text', tabIndex, ...props }) => {
  const [field, meta] = useField(props)
  const hasError = meta.touched && meta.error

  return (
      <input
          id={props.name}
          type={type}
          {...field} {...props}
          {...(tabIndex ? {tabIndex} : {})}
          className={classNames([
            'py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400',
            hasError && 'error'
          ])}/>
  )
}

export default TextField