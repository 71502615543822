import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import client from "apollo/client"
import ReactModal from "react-modal"
import { RecoilRoot } from "recoil"
import App from "App"
import AuthProvider from "providers/Auth/AuthProvider"
import Pusher from "pusher-js"
import PusherProvider from "contexts/pusher/PusherProvider"


ReactModal.setAppElement('#root')

const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <ApolloProvider client={client}>
      <RecoilRoot>
        <AuthProvider>
          <PusherProvider pusher={pusher}>
            <App/>
          </PusherProvider>
        </AuthProvider>
      </RecoilRoot>
    </ApolloProvider>
)


