import Table from "components/tables/Table"
import THead from "components/tables/THead"
import Tr from "components/tables/Tr"
import TBody from "components/tables/TBody"
import TableContainer from "components/tables/TableContainer"
import React, { useCallback, useState } from "react"
import { useQuery } from "@apollo/client"
import ContactsQuery from "queries/contacts/ContactsQuery"
import Td from "components/tables/Td"
import { BsCheck2Circle } from "react-icons/bs"
import EditContactModal from "components/contacts/EditContactModal"
import NoDataToSHow from "components/NoDataToSHow"
import TableHeader from "components/tables/TableHeader"
import { AiOutlineUserAdd } from "react-icons/ai"
import FetchMoreLine from "components/tables/FetchMoreLine"
import TableLoading from "components/tables/TableLoading"

export default function ContactsTable() {
  const [showEditContactModal, setShowEditContactModal] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)
  const { data, loading, fetchMore } = useQuery(ContactsQuery)

  const fetchMoreResult = useCallback(() => {
    fetchMore({
      variables: {
        after: data.contacts.pageInfo.endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {

        if (!fetchMoreResult) return previousResult

        return {
          ...fetchMoreResult,
          contacts: {
            ...fetchMoreResult.contacts,
            edges: [
              ...previousResult.contacts.edges,
              ...fetchMoreResult.contacts.edges
            ]
          }
        }
      },
    })
  },[data, fetchMore])

  const handlePressEditContact = (contact) => {
    setSelectedContact(contact)
    setShowEditContactModal(true)
  }
  const handleCloseEditContactModal = () => {
    setShowEditContactModal(false)
    setSelectedContact(null)
  }

  const handlePressCreateContact = () => {
    setShowEditContactModal(true)
    setSelectedContact({})
  }

  return (
      <div>
        <TableContainer>
          <TableHeader>
            <div className="w-full flex justify-end">
              <button
                  type="button"
                  className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                  onClick={handlePressCreateContact}>

                <AiOutlineUserAdd className="text-white text-xl"/>
              </button>
            </div>
          </TableHeader>
          <Table>
            <THead>
              <Tr>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Nombre
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200 ">
                      Tipo de Documento
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Numero de Documento
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Tipo de persona
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Cliente
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      Proveedor
                    </span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left">
                  <div className="group inline-flex items-center gap-x-2">
                    <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                      White Listed
                    </span>
                  </div>
                </th>
              </Tr>
            </THead>
            <TBody>
              {
                loading
                    ? (
                        <TableLoading colSpan={7}/>
                    )
                    : (
                        data.contacts.edges.length > 0
                            ? (
                                data?.contacts.edges.map(({ node }) => (
                                    <Tr key={node.id}>
                                      <Td>
                                        <div
                                            className="cursor-pointer text-blue-600 first:mt-0 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600"
                                            onClick={() => handlePressEditContact(node)}
                                        >
                                          {node.name.charAt(0)}{node.name.toLowerCase().slice(1)}
                                        </div>
                                      </Td>
                                      <Td>
                                        {node.idType}
                                      </Td>
                                      <Td>
                                        {node.idNumber}
                                      </Td>
                                      <Td>
                                        {node.personType.toLowerCase()}
                                      </Td>
                                      <Td>
                                        <div>
                                          {
                                              node.isClient &&
                                              <BsCheck2Circle className="text-green-600 m-auto w-5 h-5"/>
                                          }
                                        </div>
                                      </Td>
                                      <Td>
                                        <div className="w-full ">
                                          {
                                              node.isSupplier &&
                                              <BsCheck2Circle className="text-green-600 m-auto w-5 h-5"/>
                                          }
                                        </div>
                                      </Td>
                                      <Td>
                                        <div className="w-full ">
                                          {
                                              node.whitelistedSupplier &&
                                              <BsCheck2Circle className="text-green-600 m-auto w-5 h-5"/>
                                          }
                                        </div>
                                      </Td>
                                    </Tr>
                                ))
                            )
                            : (
                                <Tr>
                                  <Td colSpan="6">
                                    <NoDataToSHow/>
                                  </Td>
                                </Tr>
                            )

                    )
              }
              {
                data?.contacts.pageInfo.hasNextPage &&
                  <TableLoading colSpan={7}/>

              }
            </TBody>
          </Table>
        </TableContainer>

        <FetchMoreLine pageInfo={data?.contacts.pageInfo} updateFunction={fetchMoreResult}/>

        {
            selectedContact &&
            <EditContactModal
                isOpen={showEditContactModal}
                handleClose={handleCloseEditContactModal}
                data={selectedContact}/>
        }

      </div>
  )
}
