import { createBrowserRouter, Navigate, Outlet, useLocation } from "react-router-dom"
import React from "react"
import DashLayout from "./components/layouts/DashLayout"
import './index.css'
import TransactionsPage from "routes/TransactionsPage"
import LoginPage from "routes/auth/login"
import AuthPage from "routes/auth"
import useAuth from "hooks/useAuth"
import LogoutPage from "routes/auth/LogOut"
import InvoicesPage from "routes/InvoicesPage"
import ImportedFilesPage from "routes/ImportedFilesPage"
import ContactsPages from "routes/ContactsPages"
import ProductsPage from "routes/ProductsPage"

function ProtectedRoutes() {
  const location = useLocation()
  const { isAuthenticated } = useAuth()
  if (!isAuthenticated)
    return <Navigate to={{
      pathname: '/auth/login',
      search: `?from=${location.pathname}`
    }}/>

  return (
      <DashLayout>
        <Outlet/>
      </DashLayout>
  )

}

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutes/>,
    children: [
      {
        path:'',
        element: <div>Home</div>
      },
      {
        path:'/transactions',
        element: <TransactionsPage/>
      },
      {
        path:'/invoices',
        element: <InvoicesPage/>
      },
      {
        path:'/documents',
        element: <ImportedFilesPage/>
      },
      {
        path:'/contacts',
        element: <ContactsPages/>
      },
      {
        path:'/products',
        element: <ProductsPage/>
      }
    ]
  },
  {
    path:"/auth",
    element:<AuthPage/>,
    children:[
      {
        path: 'login',
        element: <LoginPage/>
      },
      {
        path: 'logout',
        element: <LogoutPage/>
      }
    ]
  }
])

export default router