import { useSearchParams } from "react-router-dom"
import { useLazyQuery } from "@apollo/client"
import ImportedFilesQuery from "queries/importedFIles/ImportedFilesQuery"
import { useCallback, useEffect, useMemo } from "react"



export default function useImportedFilesQuery (){
  const [searchParams] = useSearchParams()
  const [fetchImportedFiles, { data, loading, error, called, refetch, fetchMore }] = useLazyQuery(ImportedFilesQuery)

  const variables = useMemo(() => ({
    ...(searchParams.has('type') && { type: searchParams.get('type') }),
    ...(searchParams.has('status') && { status: searchParams.get('status') }),
    ...(searchParams.has('created_Gte') && { created_Gte: searchParams.get('created_Gte') }),
    ...(searchParams.has('created_Lte') && { created_Lte: searchParams.get('created_Lte') }),
  }), [searchParams])

  const fetch = useCallback(async () => await fetchImportedFiles({variables}), [fetchImportedFiles, variables])

  useEffect(() => {
    if (called) fetch()
  }, [called, variables, fetch])

  return {fetch, data, loading, error, variables, refetch, fetchMore}
}
