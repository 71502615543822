import { useEffect, useRef } from "react"

export default function FetchMoreLine({  updateFunction, pageInfo }) {
  const observerTarget = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (pageInfo.hasNextPage) {
          updateFunction()
        }
      }
    })

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }

  }, [observerTarget, pageInfo])

  if (!pageInfo?.hasNextPage) return null

  return (
      <div ref={observerTarget}/>
  )
}
