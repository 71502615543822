
const changeFavicon = (e) => {
  const link = document.querySelector("link[rel*='icon']")
  // Check if dark mode is enabled
  if (e.matches) {
    link.href="faviconDark.ico" // Change to your dark mode favicon path
  } else {
    link.href="faviconLight.ico" // Change to your light mode favicon path
  }
}

export default changeFavicon