import ImportedFilesTypesSelect from "components/importedFiles/ImportedFilesTypesSelect"
import ImportedFilesStatusesSelect from "components/importedFiles/ImportedFilesStatusesSelect"
import ImportedFilesDateRangeFilter from "components/importedFiles/ImportedFilesDateRangeFilter"

export default function FiltersSideBar (){
  return(
      <div className="flex flex-col p-4 bg-white w-56 rounded-xl border h-fit gap-y-5">
        <div>
          <div className="block text-sm font-medium mb-2 dark:text-white">Tipo</div>
          <ImportedFilesTypesSelect/>
        </div>
        <div>
          <div className="block text-sm font-medium mb-2 dark:text-white">Estatus</div>
          <ImportedFilesStatusesSelect/>
        </div>
        <ImportedFilesDateRangeFilter/>
      </div>
  )
}
