import personTypes from './PERSON_TYPES_CHOICES.json'
import Select from "react-select"

let options = []

for(const personType in personTypes) {
  options.push({ value: personType, label: personTypes[personType] })
}
export default function PersonTypeSelect ({field, form, ...rest}){



  const handleChange = option => {
    form.setFieldValue(field.name, option)
  }

  return (
      <Select
          options={options}
          name={field.name}
          onChange={handleChange}
          value={field.value}
          {
            ...rest
          }>
      </Select>
  )
}

