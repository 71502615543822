import { useLazyQuery } from "@apollo/client"
import TransactionStatsQuery from "queries/transactions/TransactionStatsQuery"
import StatsTable from "components/transactions/StatsTable"
import StatsCard from "components/transactions/StatsCard"
import { useSearchParams } from "react-router-dom"
import { useEffect } from "react"

export default function TransactionsStats() {
  const [fetchTransactionStats, { data, loading, error }] = useLazyQuery(TransactionStatsQuery)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const fetch = async () => {
      let variables = {
        ...(searchParams.has('date_Gte') && { date_Gte: searchParams.get('date_Gte') }),
        ...(searchParams.has('date_Lte') && { date_Lte: searchParams.get('date_Lte') }),
      }

      await fetchTransactionStats({
        variables
      })
    }

    fetch()
  }, [searchParams, fetchTransactionStats])

  return (
      <div className="flex gap-x-5 ">
        <StatsCard data={data} loading={loading} type="outcomes"/>
        <StatsCard data={data} loading={loading} type="invoices"/>
        <StatsTable data={data} loading={loading} error={error}/>
      </div>
  )
}
