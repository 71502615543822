import moment from "moment/moment"
import Currency from "components/currencies/Currency"
import React, { useContext, useEffect, useState } from "react"
import TransactionContext from "providers/TransactionProvider/TransactionContext"
import { useMutation } from "@apollo/client"
import createMatchMutation from "mutations/transactions/CreateMatchMutation"
import deleteMatchMutation from "mutations/transactions/DeleteMatchMutation"
import transactionQuery from "queries/transactions/TransactionQuery"
import classNames from "classnames"

function ids(transaction) {
  return transaction.matches.edges.map((match) => {
    const obj = {}
    obj.matchId = match.node.id
    obj.invoiceId = match.node.contentObject.id
    return obj
  })
}

export default function ElectronicInvoiceRow({ invoice }) {
  const [checked, setChecked] = useState(false)
  const [matchId, setMatchId] = useState(null)
  const transaction = useContext(TransactionContext)

  const [createMatch, { loading: createLoading }] = useMutation(createMatchMutation)

  const [deleteMatch, { loading: deleteLoading }] = useMutation(deleteMatchMutation)

  useEffect(() => {
    if (transaction) {
      const data = ids(transaction)
      const found = data.filter((id) => invoice.id === id.invoiceId)
      if (found.length === 1) {
        setChecked(true)
        setMatchId(found[0].matchId)
      }
    }
    return () => {
      setChecked(false)
      setMatchId(null)
    }
  }, [transaction, invoice])

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked
    setChecked(checked)
    if (checked) {
      createMatch({
        variables: {
          input: {
            transactionId: transaction.id,
            objectId: invoice.id
          }
        },
        refetchQueries: [{
          query: transactionQuery, variables: {
            id: transaction.id
          }
        }],
        onCompleted(data) {
          setMatchId(data.createMatch.match.id)
        }
      })
    } else {
      deleteMatch({
        variables: {
          input: {
            matchId: matchId
          }
        },
        refetchQueries: [{
          query: transactionQuery, variables: {
            id: transaction.id
          }
        }],
      })
    }
  }

  return (
      // <tr>
      //   <td className="border border-slate-600 ">{moment(invoice.issuedAt).format('DD-MM-YYYY')}</td>
      //   <td className="border border-slate-600 ">{invoice.contact.name}</td>
      //   <td className="border border-slate-600 ">
      //     <Currency amount={invoice.tax}/>
      //   </td>
      //   <td className="border border-slate-600 ">
      //     <Currency amount={invoice.total}/>
      //   </td>
      //   <td className="border border-slate-600 ">
      //     <div className="text-center">
      //       <input
      //           type="checkbox"
      //           checked={checked}
      //           disabled={createLoading || deleteLoading}
      //           onChange={handleCheckboxChange}
      //       />
      //     </div>
      //   </td>
      // </tr>
      <tr>
        <td className={classNames(["px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200", transaction && "px-3"])}>
          {moment(invoice.issuedAt).format('DD-MM-YYYY')}
        </td>
        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
          {invoice.contact.name}
        </td>
        <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
          <Currency amount={invoice.vat}/>
        </td>
        <td className={classNames([
          "px-6 py-4 text-right whitespace-nowrap text-sm font-medium",
          transaction && "text-left px-3"
        ])}>
          <Currency amount={invoice.total}/>
        </td>

        {
            transaction &&
            <td className=" flex justify-center py-4 ">
              <div className="flex items-center h-5">
                <input
                    id="hs-table-search-checkbox-1"
                    type="checkbox"
                    className="border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    checked={checked}
                    disabled={createLoading || deleteLoading}
                    onChange={handleCheckboxChange}
                />
              </div>
            </td>

        }

      </tr>
  )
}
