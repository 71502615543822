import { gql } from "@apollo/client"

const CreateImportedFileMutation = gql(`
  mutation CreateImportedFile($input: CreateImportedFileInput!) {
    createImportedFile(input: $input){
      importedFile {
        id
        file
      }
    }
  }
`)

export default CreateImportedFileMutation