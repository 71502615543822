import { gql } from "@apollo/client"

const SubCategoryFragment = gql(`
  fragment SubCategoryFields on TransactionCategoryNode {
    id
    name
    order 
    autoApply
    excludeByDefault
    excludeFromStats
  }
`)

export default SubCategoryFragment