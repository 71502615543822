import React from "react"
import {useSearchParams} from "react-router-dom"

const Arrows = () => (
    <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
          d="M7.55921 0.287451C7.86808 -0.0958171 8.40096 -0.0958167 8.70982 0.287451L12.9295 5.52367C13.3857 6.08979 13.031 7 12.3542 7H3.91488C3.23806 7 2.88336 6.08979 3.33957 5.52367L7.55921 0.287451Z"
          fill="currentColor"/>
      <path
          d="M8.70983 15.7125C8.40096 16.0958 7.86808 16.0958 7.55921 15.7125L3.33957 10.4763C2.88336 9.9102 3.23806 9 3.91488 9H12.3542C13.031 9 13.3857 9.9102 12.9295 10.4763L8.70983 15.7125Z"
          fill="currentColor"/>
    </svg>
)

const ArrowUp = () => (
    <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
          d="M8.70983 5.28755C8.40096 4.9042 7.86808 4.9042 7.55921 5.28755L3.33957 10.5238C2.88336 11.0899 3.23806 12 3.91488 12H12.3542C13.031 12 13.3857 11.0899 12.9295 10.5238L8.70983 5.28755Z"
          fill="currentColor"/>
    </svg>
)

const ArrowDown = () => (
    <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
          d="M8.70983 10.7125C8.40096 11.0958 7.86808 11.0958 7.55921 10.7125L3.33957 5.4763C2.88336 4.9102 3.23806 4 3.91488 4H12.3542C13.031 4 13.3857 4.9102 12.9295 5.4763L8.70983 10.7125Z"
          fill="currentColor"/>
    </svg>
)

export default function OrderByBtn({orderBy}) {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentValue = searchParams.get('orderBy') || ''
  const notSortedOrSortedByAnotherKey = currentValue.indexOf(orderBy) === -1

  const handleClick = () => {
    searchParams.set('orderBy', notSortedOrSortedByAnotherKey ? orderBy : currentValue === orderBy ? `-${orderBy}` : orderBy)
    setSearchParams(searchParams)
  }

  return (
      <div
          className="flex justify-center items-center w-5 h-5 border border-gray-200 group-hover:bg-gray-200 text-gray-400 rounded dark:border-gray-700 dark:group-hover:bg-gray-700 dark:text-gray-400"
          onClick={handleClick}>
        {
          notSortedOrSortedByAnotherKey
              ? <Arrows/>
              : (
                  searchParams.get('orderBy') === orderBy
                      ? <ArrowDown/>
                      : <ArrowUp/>
              )
        }
      </div>
  )
}