import { gql } from "@apollo/client"

const categorizeTransactionMutation = gql(`
  mutation categorizeTransaction($input: CategorizeTransactionInput!) {
    categorizeTransaction(input: $input) {
      success
    }
  }
`)

export default categorizeTransactionMutation