import InvoicesTable from "components/invoices/InvoicesTable"
import InvoicesFiltersSideBar from "components/invoices/InvoicesFiltersSideBar"

export default function InvoicesPage (){
  return(
      <div className="flex py-5 gap-x-5 h-full">
        <InvoicesFiltersSideBar/>
        <div className="w-full overflow-y-auto">
          <InvoicesTable/>
        </div>
      </div>
  )
}
