import Currency from "components/currencies/Currency"
import React from "react"

export default function StatsCard({ data, loading, type }) {
  if (loading) {
    return
  }

  return (
      <>
        {data &&
            <div className="flex">
              <div className="h-fit">
                <div
                    className="flex flex-col gap-y-3 lg:gap-y-3 p-4 md:p-5 bg-white border shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-800">
                  <div className="inline-flex justify-center items-center">
                    <span className="text-xs font-semibold text-gray-600 dark:text-gray-400">
                      {type !== 'outcomes' ? "Transacciones sin asignar" : "Facturas sin asignar"}
                    </span>
                  </div>

                  <div className="flex gap-x-3">
                    <div className="text-center">
                      <h3 className="text-5xl font-semibold text-gray-800 ">
                        {
                          type !== 'outcomes'
                              ? data.transactionStats.unmatchedOutcomes.count
                              : data.transactionStats.unmatchedInvoices.count
                        }
                      </h3>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                      <span className="text-gray-800">
                        <span className="text-sm text-center">
                          <Currency
                              amount={type !== 'outcomes' ? data.transactionStats.unmatchedOutcomes.amount : data.transactionStats.unmatchedInvoices.amount}/>
                        </span>
                      </span>
                      <span className=" text-sm text-gray-500">
                        Valor sin asignar
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </>
  )
}
