import {Link} from "react-router-dom"
import { IoIosContacts } from "react-icons/io"
import { SlDocs } from "react-icons/sl"
import { SiReadthedocs } from "react-icons/si"
import { GrTransaction } from "react-icons/gr"
import { AiOutlineShoppingCart } from "react-icons/ai"

export default function NavMenu() {
  return (
      <div
          id="navbar-collapse-with-animation"
          className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block">
        <div className=" overflow-hidden overflow-y-auto max-h-[75vh] scrollbar-y">
          <div
              className=" flex flex-col gap-x-0 mt-5 divide-y divide-dashed divide-gray-200 md:flex-row md:items-center md:gap-x-7 md:mt-0 md:pl-7 md:divide-y-0 md:divide-solid ">
            <div
                className="hs-dropdown [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:hover]  ">

              <button type="button"
                      className="flex items-center w-full text-gray-800 hover:text-gray-600 font-medium dark:text-gray-200 dark:hover:text-gray-500">
                Gastos
                <svg className="ml-2 w-2.5 h-2.5 text-gray-600" width="16" height="16" viewBox="0 0 16 16"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                        stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path>
                </svg>
              </button>

              <div
                  className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-80 hidden z-10 bg-white md:shadow-2xl rounded-lg py-2 md:p-2 dark:bg-gray-800 dark:divide-gray-700 before:absolute top-full before:-top-5 before:left-0 before:w-full before:h-5">
                <Link to="/transactions"
                     className="cursor-pointer inline-flex gap-x-5 w-full p-4 text-gray-600 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                  <GrTransaction className="h-5 w-5"/>
                  <div className="grow">
                    <span className="block font-semibold mb-1 text-gray-800 dark:text-gray-200">Transacciones</span>
                  </div>
                </Link>

                <div className="my-2 border-t border-gray-100 dark:border-gray-800"></div>

                <Link to='/invoices'
                      className="inline-flex gap-x-5 w-full p-4 text-gray-600 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                  <SiReadthedocs className="h-5 w-5"/>
                  <div className="grow">
                    <span className="block font-semibold mb-1 text-gray-800 dark:text-gray-200">Facturas </span>
                  </div>
                </Link>

                <div className="my-2 border-t border-gray-100 dark:border-gray-800"></div>

                <Link to='/documents'
                      className="inline-flex gap-x-5 w-full p-4 text-gray-600 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                  <SlDocs className="h-5 w-5"/>
                  <div className="grow">
                    <span className="block font-semibold mb-1 text-gray-800 dark:text-gray-200">Documentos</span>
                  </div>
                </Link>

                <div className="my-2 border-t border-gray-100 dark:border-gray-800"></div>

                <Link to='/contacts'
                      className="inline-flex gap-x-5 w-full p-4 text-gray-600 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                  <IoIosContacts className="w-5 h-5"/>
                  <div className="grow">
                    <span className="block font-semibold mb-1 text-gray-800 dark:text-gray-200">Contacts</span>
                  </div>
                </Link>

                <div className="my-2 border-t border-gray-100 dark:border-gray-800"></div>

                <Link to='/products'
                      className="inline-flex gap-x-5 w-full p-4 text-gray-600 rounded-md hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                  <AiOutlineShoppingCart className="w-5 h-5"/>
                  <div className="grow">
                    <span className="block font-semibold mb-1 text-gray-800 dark:text-gray-200">Productos</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

