import {useEffect, useRef} from "react"
import {Formik} from "formik"
import {useRecoilValue} from "recoil"
import {useMutation} from "@apollo/client"
import Loading from "components/Loading"
import useTransactionsQuery from "hooks/transactions/useTransactionsQuery"
import selectedAssignedCategoryIdState from "atoms/categories/selectedAssignedCategoryState"
import transactionsQuery from "queries/transactions/TransactionsQuery"
import categorizeTransactionsMutation from "mutations/transactions/CategorizeTransactionsMutation"
import categorizeSimilarTransactionsMutation from "mutations/transactions/categorizeSimilarTransaccionsMutation"

export default function BtsConfirmationBar({transactionData}) {
  const selectedAssignedCategoryId = useRecoilValue(selectedAssignedCategoryIdState)
  const {variables} = useTransactionsQuery()

  const formikRef = useRef()

  const [categorizeTransactions, {loading: loadingCategorizeTransactions}] = useMutation(categorizeTransactionsMutation)
  const [categorizeSimilarTransactions, {loading: loadingCategorizeSimilarTransactions}] = useMutation(categorizeSimilarTransactionsMutation)

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
          'check',
          false
      )
    }

  }, [selectedAssignedCategoryId])


  const handleSubmit = async (values) => {
    const options = {
      variables: {
        input: {
          transactionId: transactionData.id,
          categoryId: selectedAssignedCategoryId
        }
      },
      refetchQueries: [
        {
          query: transactionsQuery,
          variables
        }
      ]
    }
    if (values.check)
      await categorizeSimilarTransactions(options)
    else
      await categorizeTransactions(options)
  }

  return (
      <Formik innerRef={formikRef} initialValues={{check: false}} onSubmit={handleSubmit}>
        {
          ({handleChange, values, handleSubmit}) => (
              <div className="flex h-full justify-evenly align-middle ">
                <div className="flex gap-1.5 items-center w-1/2 ">
                  <label
                      className="text-xs text-gray-500">
                    Aplicar a todas
                  </label>
                  <input
                      type="checkbox"
                      checked={values.check}
                      name="check"
                      onChange={handleChange}
                      className="relative shrink-0 w-[3.25rem] h-7 bg-gray-100 checked:bg-none checked:bg-blue-600 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-blue-600 dark:focus:ring-offset-gray-800 before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200"/>
                </div>
                <div className="flex items-center">
                  <button
                      type="button"
                      className="w-24 h-11 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                      onClick={handleSubmit}>
                    {
                      loadingCategorizeTransactions || loadingCategorizeSimilarTransactions
                          ? <Loading/>
                          : 'Aplicar'

                    }
                  </button>
                </div>
              </div>
          )
        }

      </Formik>
  )
}
