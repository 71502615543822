import TransactionsTable from "components/transactions/TransactionsTable"
import { useQuery } from "@apollo/client"
import transactionCategoriesQuery from "queries/transactions/TransactionCategoriesQuery"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import TransactionsStats from "components/transactions/TransactionsStats"
import FiltersBar from "components/transactions/FiltersBar"
import EditCategorySideBar from "components/transactions/EditCategorySideBar"

export default function TransactionsPage() {
  const {data} = useQuery(transactionCategoriesQuery)
  const [searchParams, setSearchParams] = useSearchParams()


  useEffect(() => {
    if (data && searchParams.size === 0) {
      searchParams.append('amount_Lt', '0')
      let idsToExclude = []
      if (data) {
        data.transactionCategories.edges.forEach((category) => {
          category.node.subCategories.edges.forEach((subCategory) => {
            if (subCategory.node.excludeByDefault) {
              idsToExclude.push(subCategory.node.id)
            }
          })
        })
      }

      if (idsToExclude)
        idsToExclude.forEach(id => searchParams.append('exclude_Category_In', id))

      setSearchParams(searchParams)
    }
  }, [data])

  return (
      <div className="relative flex gap-x-5 h-full ">
        <div className="py-5">
          <FiltersBar/>
        </div>
        <div className="py-5 w-full overflow-y-auto flex flex-col gap-y-5">
          <TransactionsStats/>
          <TransactionsTable/>
        </div>
        <EditCategorySideBar/>
      </div>
  )
}