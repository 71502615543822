import types from "./TYPE_CHOICES.json"
import Select from "react-select"

export default function TypesSelect({ selectedType, ...rest }) {

  let options = []

  for (const type in types) {
    options.push({ value: type, label: types[type] })
  }

  return (
      <Select
          options={options}
          {...{
            ...(selectedType?.length > 0
                ? { value: options.filter((option) => selectedType.includes(option.value)) }
                : {}),
            ...rest
          }}/>
  )
}
