import {useCallback, useEffect, useMemo} from "react"
import {useLazyQuery} from "@apollo/client"
import {useSearchParams} from "react-router-dom"
import transactionsQuery from "queries/transactions/TransactionsQuery"

// TODO look for the way to cancel current request when fetching again

export default function useTransactionsQuery() {
  const [searchParams] = useSearchParams()
  const [fetchTransactions, {data, called, loading, error, refetch, fetchMore}] = useLazyQuery(transactionsQuery)

  const variables = useMemo(() => ({
    ...(searchParams.has('after') && {after: searchParams.get('after')}),
    ...(searchParams.has('amount_Lt') && {amount_Lt: Number(searchParams.get('amount_Lt'))}),
    ...(searchParams.has('amount_Gt') && {amount_Gt: Number(searchParams.get('amount_Gt'))}),
    ...(searchParams.has('category') && {category: searchParams.get('category')}),
    ...(searchParams.has('date_Gte') && {date_Gte: searchParams.get('date_Gte')}),
    ...(searchParams.has('date_Lte') && {date_Lte: searchParams.get('date_Lte')}),
    ...(searchParams.has('exclude_Category_In') && {exclude_Category_In: searchParams.getAll('exclude_Category_In').join(',')}),
    ...(searchParams.has('matches_Isnull') && {matches_Isnull: searchParams.get('matches_Isnull') === 'true'}),
    ...(searchParams.has('name_Icontains') && {name_Icontains: searchParams.get('name_Icontains')}),
    ...(searchParams.has('orderBy') && {orderBy: searchParams.get('orderBy')}),
  }), [searchParams])

  const fetch = useCallback(async () => await fetchTransactions({variables}), [fetchTransactions, variables])

  useEffect(() => {
    if (called) fetch()
  }, [called, variables, fetch])

  return {fetch, data, loading, error, variables, refetch, fetchMore}
}