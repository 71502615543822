import {gql} from "@apollo/client"

const transactionsQuery = gql(`
  query Transactions(
    $matches_Isnull: Boolean,
    $first: Int,
    $after: String,
    $orderBy: String,
    $name_Icontains: String,
    $date_Gte: Date,
    $date_Lte: Date,
    $category: ID,
    $amount_Lt: Decimal,
    $amount_Gt: Decimal,
    $exclude_Category_In: String,
  ) {
  transactions(
    matches_Isnull: $matches_Isnull,
    first: $first,
    after: $after,
    orderBy: $orderBy,
    date_Gte: $date_Gte,
    date_Lte: $date_Lte,
    name_Icontains: $name_Icontains,
    category: $category,
    amount_Lt: $amount_Lt,
    amount_Gt: $amount_Gt,
    exclude_Category_In: $exclude_Category_In
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
    totalAmount
    edges {
      node {
        ...TransactionFields
      }
    }
  }
}
`)

export default transactionsQuery