import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { FormError, FormGrid, Label, TextField } from 'components/forms'
import InLineLink from 'components/text/InLineLink'
import Button from 'components/buttons/Button'
import { ErrorToast } from 'components/toasts'
import useAuth from "hooks/useAuth"

export default function LoginForm() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { login } = useAuth()
  const initialValues = {
    username: '',
    password: ''
  }
  const validationSchema = Yup.object({
    username: Yup.string().required('Este campo es requerido'),
    password: Yup.string()
        .required('Este campo es requerido'),
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/token/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      const data = await response.json()

      if (data?.non_field_errors) {
        if (data.error?.name === 'ValidationError')
          toast.custom(() => (<ErrorToast>El correo o la contraseña son inválidos</ErrorToast>))
      } else {
        const from = searchParams.get('from')
        login(data.auth_token)
        navigate(from ? from : '/', { replace: true })
      }
    } catch (error) {
      // TODO report to sentry?
      console.error(error)
      // setError(error?.message ?? "Something went wrong!")
    } finally {
      // TODO set is submitting
      setSubmitting(false)
    }
  }

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit} >
        {({ isSubmitting }) => (
            <Form noValidate>
              <FormGrid>
                <div>
                  <Label htmlFor="username">Correo</Label>
                  <TextField label="Correo" name="username" type="email" tabIndex={1}/>
                  <FormError name="username"/>
                </div>
                <div>
                  <div className="flex justify-between items-center">
                    <Label htmlFor="password">Contraseña</Label>
                    <InLineLink to="/auth/forgot-password" tabIndex={3}>
                      ¿Olvidaste la contraseña?
                    </InLineLink>
                  </div>
                  <TextField label="Contraseña" name="password" type="password" tabIndex={2}/>
                  <FormError name="password"/>
                </div>
                <div className="flex items-center">
                  <div className="flex">
                    <input id="remember-me" name="remember-me" type="checkbox"
                           className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"/>
                  </div>
                  <div className="ml-3">
                    <label htmlFor="remember-me" className="text-sm dark:text-white">Recordarme</label>
                  </div>
                </div>
                <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  Ingresar
                </Button>
              </FormGrid>
            </Form>
        )}
      </Formik>
  )
}
