import Header from "components/main/Header"
import ChatSideBar from "components/main/ChatSideBar"
import { useRecoilValue } from "recoil"
import chatIsOpenState from "atoms/main/chatIsOpenState"
import classNames from "classnames"
import ChatBtn from "components/chat/ChatBtn"
import { useLocation } from "react-router-dom"

export default function DashLayout({ children }) {
  const chatIsOpen = useRecoilValue(chatIsOpenState)
  const location = useLocation()

  const FULL_WIDTH_PATHS = [
    '/transactions',
    '/documents',
    '/invoices',
    '/contacts',
    '/products'
  ]

  return (
      <div className="bg-gray-50 dark:bg-slate-900 h-screen overflow-y-hidden	">
        <Header/>
        {/*<Breadcrumb/>*/}
        {/*<Sidebar/>*/}
        <ChatSideBar/>
        <ChatBtn/>
        {
          (FULL_WIDTH_PATHS.indexOf(location.pathname) > -1)
              ? (
                  <div className="bg-gray-50 w-full h-screen -mt-[71px] pt-[71px]">
                    <div className={classNames([
                      "w-full h-full  px-4 sm:px-6 md:px-5 ",
                      "transition-all duration-300",
                      chatIsOpen && "lg:pr-[410px]"
                    ])}>
                      {children}
                    </div>
                  </div>
              )
              : (
                  <div className="h-screen -mt-[71px] pt-[71px]">
                    {children}
                  </div>

              )
        }


      </div>
  )
}
