export default function Currency ({amount}){

  let formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 2
  });

  return (
      <span>
        {formatter.format(parseFloat(amount))}
      </span>

  )
}
