import { gql } from "@apollo/client"

const TransactionStatsQuery = gql(`
    query TransactionStats($date_Gte: Date, $date_Lte: Date) {
      transactionStats(date_Gte: $date_Gte, date_Lte: $date_Lte) {
        transactions {
          amount
          count
        }
        incomes {
          amount
          count
        }
        outcomes {
          amount
          count
        }
        unmatchedOutcomes {
          amount
          count
        }
        unmatchedInvoices {
          amount
          count
        }
      }
    }
`)

export default TransactionStatsQuery