import {useSearchParams} from "react-router-dom"
import CategorySelect from "components/transactions/CategorySelect"

export default function TransactionCategoryFilter() {
  const [searchParams, setSearchParams] = useSearchParams()

  // TODO handle multi

  const handleChange = option => {
    if (option)
      searchParams.set('category', option.value)
    else
      searchParams.delete('category')
    setSearchParams(searchParams)
  }

  return (
      <CategorySelect
          selectedCategories={searchParams.getAll('category')}
          categoriesToExclude={searchParams.getAll('exclude_Category_In')}
          isClearable
          onChange={handleChange}/>
  )
}