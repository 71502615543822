import { useSearchParams } from "react-router-dom"
import CategorySelect from "components/transactions/CategorySelect"

export default function ExcludeCategorySelect() {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChange = options => {
    searchParams.delete('exclude_Category_In')
    if (options.length > 0){
      options.forEach((option) => {
        if (option) searchParams.append('exclude_Category_In', option.value)
      })
    }
    setSearchParams(searchParams)
  }

  return (
      <CategorySelect
          selectedCategories={searchParams.getAll('exclude_Category_In')}
          isMulti
          isClearable
          onChange={handleChange}/>
  )
}
