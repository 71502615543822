export default function  Loading(){
  return(
      <div className="flex w-full justify-center " role="alert">
        <div className="flex items-center">
          <div
              className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
              role="status" aria-label="loading">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
  )
}
