import classNames from "classnames"
import ChatMessageLogo from "components/chat/ChatMessageLogo"

export default function ChatMessage({message, incoming}) {
  return (
      <li
          id={message.id}
          className={classNames([
            'flex gap-2 py-2 px-4',
            incoming ? 'flex-row-reverse' : 'justify-end'
          ])}>
        <div className="bg-white rounded-lg shadow-lg min-w-0">
          <p className={classNames([
            'text-gray-800 text-sm my-auto py-2 px-4 break-words',
            !incoming && 'text-right'
          ])}>
            {message.msg}
          </p>
        </div>
        <ChatMessageLogo type={!incoming && 'user'}/>
      </li>
  )
}