import React from "react"

export default function Td({children, ...rest}) {
  return (
      <td className="h-px w-px whitespace-nowrap" {...rest}>
        {/* TODO I've removed z-10 from this div, investigate... */}
        <div className="block relative">
          <div className="px-6 py-2">
            {children}
          </div>
        </div>
      </td>
  )
}