import React from "react"
import { useQuery } from "@apollo/client"
import transactionQuery from "queries/transactions/TransactionQuery"
import HeaderModal from "components/HeaderModal"
import MatchesTable from "components/MatchesTable"
import TransactionProvider from "providers/TransactionProvider"
import InvoicesTable from "components/invoices/InvoicesTable"
import N1Modal from "components/N1Modal"
import { useRecoilState, useSetRecoilState } from "recoil"
import ShowTransactionModalState from "atoms/transactions/showTransactionModalState"
import selectedTransactionState from "atoms/transactions/selectedTransactionState"

export default function TransactionModal ({transactionId}){
  const [showTransactionModal, setShowTransactionModal] = useRecoilState(ShowTransactionModalState)
  const setSelectedTransaction = useSetRecoilState(selectedTransactionState)

  const{data, loading} = useQuery(transactionQuery, {
    variables: {
      id: transactionId
    }
  })


  const handleCloseModal = () => {
    setShowTransactionModal(false)
    setSelectedTransaction(null)
  }

  return(
      <N1Modal
          isOpen={showTransactionModal} onClose={handleCloseModal} name={data?.transaction.name} >
        <TransactionProvider transaction={data?.transaction }>
          {
            loading
                ? <div>Cargando...</div>
                : (
                    <div className="p-5 relative">
                      <HeaderModal data={data}/>
                      <MatchesTable data={data}/>
                      <hr className="my-5"/>
                      <div className="h-[400px] overflow-scroll">
                        <InvoicesTable/>
                      </div>
                    </div>
                )
          }</TransactionProvider>
      </N1Modal>
  )
}
