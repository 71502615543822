import { ApolloClient, ApolloLink, gql, InMemoryCache } from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { onError } from "@apollo/client/link/error"
import { createFragmentRegistry } from "@apollo/client/cache"
import SubCategoryFragment from "queries/transactions/SubCategoryFragment"
import TransactionFragment from "queries/transactions/TransactionFragment"

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    new createUploadLink({uri: `${process.env.REACT_APP_API_URL}/graphql`})
  ]),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(
        gql`
          ${SubCategoryFragment}
          
          ${TransactionFragment}
        `
    ),

    // ...policies
  })
})

export default client