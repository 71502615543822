import { gql } from "@apollo/client"

const CreateContactMutation = gql(`
  mutation CreateContact ($input: CreateContactInput!) {
    createContact(input: $input) {
        contact {
            id
        
        }
    }
  }
`)

export default CreateContactMutation