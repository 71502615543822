import AuthContext from "providers/Auth/AuthContext"
import {
  getToken as getTokenLocalStorage,
  setToken as setTokenLocalStorage,
  deleteToken as deleteTokenLocalStorage,
} from 'storage/storage'
import { useEffect, useState } from "react"

export default function AuthProvider({ children }) {
  const [token, setToken] = useState(getTokenLocalStorage())

  useEffect(() => {
    if (token) {
      setTokenLocalStorage(token)
    } else {
      deleteTokenLocalStorage()
    }
  }, [token])

  const login = (jwt) => {
    setToken(jwt)
  }

  const logout = () => {
    setToken('')
  }

  return (
      <AuthContext.Provider
          value={{
            token,
            isAuthenticated: token !== '',
            login,
            logout
          }}>
        {children}
      </AuthContext.Provider>
  )
}
