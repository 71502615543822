import { useSearchParams } from "react-router-dom"
import TypesSelect from "components/importedFiles/TypesSelect"

export default function ImportedFilesTypesSelect (){
  const [searchParams, setSearchParams] = useSearchParams()
  const handleChange = option => {
    if (option)
      searchParams.set('type', option.value)
    else
      searchParams.delete('type')
    setSearchParams(searchParams)
  }

  return(
      <TypesSelect
          selectedType={searchParams.getAll('type')}
          isClearable
          onChange={handleChange}/>
  )
}
