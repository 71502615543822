import classNames from "classnames"
import PropTypes from "prop-types"

export default function N1SideBarHelper({left, isOpen, children}) {
  return (
      <div
          className={classNames([
            "absolute transition-all duration-300 transform -right-5 h-full w-80 bg-white z-50 border-l lg:block",
            !isOpen && "translate-x-full hidden",
            isOpen && "translate-x-0 visible",
          ])}>
        {children}
      </div>
  )
}

N1SideBarHelper.propTypes = {
  left: PropTypes.bool
}