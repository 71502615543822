import LoginForm from "components/auth/LoginForm"
import AuthBox from "components/auth/AuthBox"

export default function LoginPage (){
  return(
      <AuthBox
          title="Ingresar"
          subtitle="¿Aún no tienes una cuenta?"
          subtitleLinkText="Crea una aquí"
          subtitleLinkTo="/auth/signup">
        <LoginForm/>
      </AuthBox>
  )
}
