import React from "react"
import ContactsTable from "components/contacts/ContactsTable"

export default function ContactsPages (){
  return(
        <div className="h-full py-5 w-full overflow-y-auto" >
          <ContactsTable/>
        </div>
  )
}
