import { DateRangePicker, defaultTheme, Provider, } from '@adobe/react-spectrum'
import { useSearchParams } from "react-router-dom"
import { DateTime } from "luxon"

function formatDateForFilter(date) {
  const currentDate = new Date()
  const day = Number(date.day) > 10 ? date.day : `0${date.day}`
  const month = Number(date.month) > 10 ? date.month : `0${date.month}`
  const hours = currentDate.getHours() > 10 ? currentDate.getHours() : `0${currentDate.getHours()}`
  const minutes = currentDate.getMinutes() > 10 ? currentDate.getMinutes() : `0${currentDate.getMinutes()}`
  return `${
      DateTime.fromFormat(`${date.year}-${month}-${day} ${hours}:${minutes}:00`, 'yyyy-MM-dd hh:mm:ss', {
        zone: DateTime.local().zoneName
      }).toUTC().toISO()}`
}

export default function ImportedFilesDateRangeFilter() {
  const [searchParams, setSearchParams] = useSearchParams()
  const handleChange = ({ start, end }) => {
    searchParams.set('created_Gte', `${formatDateForFilter(start)}`)
    searchParams.set('created_Lte', `${formatDateForFilter(end)}`)
    setSearchParams(searchParams)
  }


  const handleClearBtn = () => {
    searchParams.delete('created_Gte')
    searchParams.delete('created_Lte')
    setSearchParams(searchParams)
  }

  return (
      <Provider theme={defaultTheme}>
        <div className="relative">
          <DateRangePicker
              label={'Fecha'}
              onChange={handleChange}/>
          {
              searchParams.has('created_Gte') &&
              <div className="absolute bottom-1.5 right-10 ">
                <button onClick={handleClearBtn} className="text-gray-500">
                  X
                </button>
              </div>
          }
        </div>
      </Provider>
  )
}
