import { gql } from "@apollo/client"

const TransactionFragment = gql(`
  fragment TransactionFields on TransactionNode {
    id
    date
    name
    amount
    hasMatches
    category {
      id
      name
    }
  }
`)

export default TransactionFragment