import { gql } from "@apollo/client"

const transactionsQuery = gql(`
  query Transaction($id: ID!) {
    transaction(id: $id) {
        id
        name
        date
        amount
        category{
          id
          name
          excludeByDefault
          autoApply
        }
        matches {
            edges {
                node {
                    id
                    contentObject {
                        id
                        issuedAt
                        vat
                        total
                        contact {
                            id
                            legalName
                            idNumber
                            idType
                        }
                    }
                }
            }
        }
    }
}
`)

export default transactionsQuery