import React from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { AiOutlineSearch } from "react-icons/ai"
import { IoDocumentAttachSharp } from "react-icons/io5"
import Currency from "components/currencies/Currency"
import Td from "components/tables/Td"
import selectedTransactionIdState from "atoms/transactions/selectedTransactionState"
import showTransactionModalState from "atoms/transactions/showTransactionModalState"
import selectedAssignedCategoryIdState from "atoms/categories/selectedAssignedCategoryState"
import showEditCategorySidebarState from "atoms/transactions/showEditCategorySidebarState"
import classNames from "classnames"

export default function TransactionsTableRow({ node }) {
  const [selectedTransactionId,setSelectedTransactionId] = useRecoilState(selectedTransactionIdState)
  const setShowTransactionModal = useSetRecoilState(showTransactionModalState)
  const setSelectedAssignedCategoryId = useSetRecoilState(selectedAssignedCategoryIdState)
  const setShowEditCategorySidebar = useSetRecoilState(showEditCategorySidebarState)

  const handleInvoiceBtnClick = () => {
    setSelectedTransactionId(node.id)
    setShowTransactionModal(true)
  }

  const handleOpenEditCategoryModal = (categoryId) => {
    setSelectedTransactionId(node.id)
    setShowEditCategorySidebar(true)
    if(categoryId){
      setSelectedAssignedCategoryId(categoryId)
    }else{
      setSelectedAssignedCategoryId(null)
    }
  }

  return (
      <tr className={classNames([
          " hover:bg-gray-50 dark:bg-slate-900 dark:hover:bg-slate-800",
        selectedTransactionId === node.id && 'bg-blue-50' ])}>
        <Td className="h-px w-px whitespace-nowrap">
          <p className="text-sm text-gray-500">
            {node.date}
          </p>
        </Td>
        <Td>
          <p className="text-sm text-gray-500">
            {node.name}
          </p>
        </Td>
        <Td>
          {
            node.category
                ?
                <span
                    onClick={() => handleOpenEditCategoryModal(node.category.id)}
                    data-hs-overlay="#show-edit-category-modal"
                    className="inline-flex items-center gap-1.5 py-1 px-2 rounded-md text-xs font-medium bg-emerald-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200 cursor-pointer">
                  {node.category.name}
                </span>
                :
                <span
                    onClick={() => handleOpenEditCategoryModal()}
                    className="inline-flex items-center gap-1.5 py-1 px-2 rounded-md text-xs font-medium bg-gray-100 text-gray-800 cursor-pointer">
                  Sin Categoría
                </span>
          }
        </Td>
        <Td>
          <div
              className="flex justify-center text-gray-800 cursor-pointer dark:text-white"
              onClick={handleInvoiceBtnClick}>
            {
              node.hasMatches
                  ? <IoDocumentAttachSharp/>
                  : <AiOutlineSearch/>
            }
          </div>
        </Td>
        <Td className="h-px w-px whitespace-nowrap">
          <p className="text-sm text-gray-500">
            <Currency amount={node.amount}/>
          </p>
        </Td>
      </tr>
  )
}