import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import chatMessagesState from "atoms/chat/chatMessagesState"
import ChatMessage from "components/chat/ChatMessage"


export default function ChatMessages() {
  const chatMessages = useRecoilValue(chatMessagesState)

  useEffect(() => {
    const lastElement = chatMessages.slice(-1)
    const lastMessage = document.getElementById(`${lastElement[0].id}`)
    if (lastMessage) {
      lastMessage.scrollIntoView({behavior: 'smooth'})
    }
  }, [chatMessages])

  return (
      <div className="grow overflow-y-auto p-3">
        <ul className="space-y-5">
          {
            chatMessages.map((message) => (
                <ChatMessage
                    key={message.id}
                    incoming={message.origin !== 'user'}
                    message={message}/>
            ))
          }
        </ul>
      </div>
  )
}
