export default function DotsLoading() {
  return (
      <div className="fixed bottom-28 w-full flex justify-center ">
        <div className="bg-white p-3 rounded-full flex gap-x-1 shadow-lg">
          <div style={{ animationDelay: '0.1s' }} className="bg-blue-500 w-2 h-2 rounded-full animate-bounce "></div>
          <div style={{ animationDelay: '0.2s' }} className="bg-blue-500 w-2 h-2 rounded-full animate-bounce "></div>
          <div style={{ animationDelay: '0.3s' }} className="bg-blue-500 w-2 h-2 rounded-full animate-bounce "></div>
        </div>
      </div>
  )
}
