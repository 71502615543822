import { Link } from "react-router-dom"

export default function InLineLink({ to, children }) {
  return (
      <Link
          className="text-sm text-blue-600 decoration-2 hover:underline font-medium"
          to={to}>
        {children}
      </Link>
  )
}