import TableContainer from "components/tables/TableContainer"
import TBody from "components/tables/TBody"
import Currency from "components/currencies/Currency"
import Td from "components/tables/Td"
import React from "react"
import Table from "components/tables/Table"

export default function StatsTable({data, loading, error}) {


  if(error){
    return (
        <div>Ocurrio un error al traer la data</div>
    )
  }

  return (
      <>
        {
          loading
              ? <></>
              : (
                  data &&
                  <div className="w-fit ">
                    <TableContainer>
                      <Table>
                        <TBody>
                          <tr><Td className="h-px w-px whitespace-nowrap">
                            <p className="text-sm text-gray-500">
                              Saldo Total
                            </p>
                          </Td>
                            <Td className="h-px w-px whitespace-nowrap text-right">
                              <p className="text-sm text-gray-500">
                                <Currency amount={data.transactionStats.transactions.amount}/>
                              </p>
                            </Td>
                          </tr>
                          <tr><Td className="h-px w-px whitespace-nowrap">
                            <p className="text-sm text-gray-500">
                              Valor total de ingresos
                            </p>
                          </Td>
                            <Td className="h-px w-px whitespace-nowrap text-right">
                              <p className="text-sm text-gray-500">
                                <Currency amount={data.transactionStats.incomes.amount}/>
                              </p>
                            </Td>
                          </tr>
                          <tr>
                            <Td className="h-px w-px whitespace-nowrap">
                              <p className="text-sm text-gray-500">
                                Valor total de gastos
                              </p>
                            </Td>
                            <Td className="h-px w-px whitespace-nowrap text-right">
                              <p className="text-sm text-gray-500">
                                <Currency amount={data.transactionStats.outcomes.amount}/>
                              </p>
                            </Td>
                          </tr>
                        </TBody>
                      </Table>
                    </TableContainer>
                  </div>
              )
        }
      </>
  )
}
