import { gql } from "@apollo/client"

const ContactsQuery = gql(`
    query Contacts($after: String) {
        contacts( after: $after ) {
            edges {
                node {
                    id
                    idType
                    idNumber
                    personType
                    name
                    legalName
                    firstName
                    lastName 
                    addressLine1
                    addressLine2
                    postalCode
                    email
                    phone
                    isClient
                    isSupplier
                    whitelistedSupplier 
                }
            }
            pageInfo {
              hasNextPage
              endCursor
              startCursor
            }
        }
    }
`)

export default ContactsQuery